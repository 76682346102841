import React from 'react'
import { Button, Grid, Modal } from 'semantic-ui-react'
import { connect } from 'react-redux';

function exampleReducer(state, action) {
  switch (action.type) {
    case 'CONFIG_CLOSE_ON_DIMMER_CLICK':
      return { ...state, closeOnDimmerClick: action.value }
    case 'CONFIG_CLOSE_ON_ESCAPE':
      return { ...state, closeOnEscape: action.value }
    case 'OPEN_MODAL':
      return { ...state, open: true }
    case 'CLOSE_MODAL':
      return { ...state, open: false }
    default:
      throw new Error()
  }
}

function ModalExampleCloseConfig({toggleElement, allServices, servicesSummary}) {
  const [state, dispatch] = React.useReducer(exampleReducer, {
    closeOnEscape: false,
    closeOnDimmerClick: false,
    open: false,
    dimmer: undefined,
  })
  const { open, closeOnEscape, closeOnDimmerClick } = state

  const getServiceName = (service) => {
      for(let i = 0; i < allServices.length; i++){
        if (allServices[i].id == service){
          return (allServices[i].name)
        }
      }
  }


  const serviceItem = (service, key) => {
    return <div key={key} style={{ display: 'flex' }}><div style={{ width: 80 }}>ID : {service}</div><div style={{ width: 24 }}> - </div><div>{getServiceName(service)}</div></div>
  }
  return (
    <Grid columns={1}>
      <Grid.Column>
        <Modal
          size={'small'}
          closeOnEscape={closeOnEscape}
          closeOnDimmerClick={closeOnDimmerClick}
          open={open}
          onOpen={() => dispatch({ type: 'OPEN_MODAL' })}
          onClose={() => dispatch({ type: 'CLOSE_MODAL' })}
          trigger={toggleElement}
          style={{zIndex:99999}}
        >
          <Modal.Header>Список активных сервисов</Modal.Header>
          <Modal.Content>
            {servicesSummary.data.online_ids.map(
              (service, key) => { return serviceItem(service, key) }
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => dispatch({ type: 'CLOSE_MODAL' })} positive>
              ок
            </Button>
          </Modal.Actions>
        </Modal>
      </Grid.Column>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    allServices: state.allServices,
    servicesSummary: state.servicesSummary,
    dispatch: state.dispatch
  };
};

export default connect(mapStateToProps)(ModalExampleCloseConfig);
