/* eslint-disable guard-for-in */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import moment from 'moment';
import { connect } from 'react-redux';
import { Loader, Segment } from 'semantic-ui-react';
import {
  ResponsiveContainer,
  LineChart as LC,
  Line as L,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';
import { getDataForKey } from '../../../store/actions';
import { getKeyHistory } from '../../../store/actions/socketActions';
import { keysByDate } from '../../../_helpers';
import { TEXT_KEYS_QUANTITY } from '../../../constants';

window.moment = moment;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const handleDateChange = (date) => {
  date.preventDefault();
};

const LineChart = ({
  dataId, dateForKey, dispatch, socketConnection, allUsersProperty
}) => {
  const [dataLC, setDataLC] = useState([]);
  const [loadingComponentStatus, setLoadingComponentStatus] = useState(false);
  const [interval, setInterval] = React.useState(0);

  useEffect(() => {
    if (dateForKey) {
      console.log(dateForKey);
      const prepareDate = keysByDate(dateForKey, 'countDayKeys', 'sentAt', interval);
      const dataForLC = [];
      for (const k in prepareDate) {
        dataForLC.push({ name: k, [TEXT_KEYS_QUANTITY]: prepareDate[k] });
      }
      // console.log('dataForLC: ', dataForLC, prepareDate, dateForKey)
      setDataLC(dataForLC);
      setLoadingComponentStatus(false);
    }
  }, [dateForKey, interval]);

  const handleChangeInterval = (event) => {
    setInterval(event.target.value);
  };

  const handleSubmit = (date) => {
    setLoadingComponentStatus(true);
    date.preventDefault();
    const start = date.currentTarget[0];
    const end = date.currentTarget[1];
    const dataId = date.currentTarget[2];
    const timeStampStart = Date.parse(moment(new Date(start.value)));
    const timeStampEnd = Date.parse(moment(new Date(end.value)));
    const Global = typeof window !== 'undefined' ? window : global;
    const { localStorage } = Global;
    const authkeyStorage = localStorage.getItem('authkey');
    const keyStorage = localStorage.getItem('key');

    const fetchOptions = {
      authkey: authkeyStorage, key: keyStorage,
    };
    // TODO настроить передачу даты, формат
    getKeyHistory({
      socketConnection,
      fetchOptions,
      key_id: dataId.value,
      from: timeStampStart,
      to: timeStampEnd
    });
    dispatch(getDataForKey({ start }));
  };

  const classes = useStyles();

  // console.log('dataLC: ', dataLC)
  // if (!allUsersProperty){
  // // if (!allUsersProperty || !allUsersProperty.from){
  //   return null
  // }

  const CustomLine = () => {

    return (
      <ResponsiveContainer width="100%" height={300}>
        <LC width={500} height={300} data={dataLC}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
          <YAxis padding={{ top: 30 }} />
          <Tooltip />
          <L
            type="monotone"
            dataKey={TEXT_KEYS_QUANTITY}
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LC>
      </ResponsiveContainer>
    );
  };

  const sumall = dataLC.map(item => item[TEXT_KEYS_QUANTITY]).reduce((prev, curr) => prev + curr, 0);
  // console.log(sumall);

  return (
    <>
      <div className="header">
        <form
          className={classes.container}
          noValidate
          style={{
            marginTop: 24, flexWrap: 'nowrap', alignItems: 'center', backgroundColor: 'lavender', padding: 16
          }}
          onSubmit={handleSubmit}
        >
          <TextField
            id="datetime-local-start"
            label="Дата начала"
            type="datetime-local"
            defaultValue={moment(allUsersProperty.from).format('YYYY-MM-DDThh:mm')}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => handleDateChange(e)}
          />
          <TextField
            id="datetime-local-end"
            label="Дата окончания"
            type="datetime-local"
            defaultValue={moment().format('YYYY-MM-DDThh:mm')}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="dataId"
            label="dataId"
            type="label"
            defaultValue={dataId}
            style={{ display: 'none' }}
          />
          <FormControl variant="outlined" >
            <InputLabel id="demo-simple-select-outlined-label">Интервал</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={interval}
              onChange={handleChangeInterval}
              label="Интервал"
            >

              <MenuItem value={0}>по дням</MenuItem>
              <MenuItem value={1}>по 1/2 дня</MenuItem>
              <MenuItem value={2}>по 1/4 дня</MenuItem>
              <MenuItem value={3}>по часам</MenuItem>
              <MenuItem value={4}>по 15 минут</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="small"
            style={{ height: 36, marginLeft: 8 }}
            disabled={loadingComponentStatus}
          >
            ok
          </Button>
        </form>
        <h4
          className="title"
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          Использование ключа за период по дням
        </h4>
        <h4
          className="title"
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          Запросов за период: {sumall}
        </h4>
        <div className="links" />
      </div>
      <Segment>
        <Loader active={loadingComponentStatus} />
        {CustomLine()}
      </Segment>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allUsersProperty: state.allUsersProperty,
    socketConnection: state.socketConnection,
    dateForKey: state.dateForKey,
    dispatch: state.dispatch
  };
};
export default connect(mapStateToProps)(LineChart);
