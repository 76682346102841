import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { connect } from 'react-redux';
import { CLEAR_CLOSE_MODAL_SAVE_DATA, DELETE_MODAL_DATA, MODAL_CLOSE_ACTION, MODAL_OPEN_ACTION } from '../../constants';
import { clearDataForKey, openModalAction } from '../../store/actions';
import * as moment from 'moment';
import 'moment/locale/ru';
import LineChart from '../../views/reports/LineChart';
import LineChartRecharts from '../../views/reports/LineChart/LineChartRecharts';
moment.locale('ru')


function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function SimpleModal({dispatch, modalData}) {
  // console.log("SimpleModal data: ", modalData)
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  React.useEffect(()=>{
    // console.log('check modalData')
    if (modalData && modalData.action === MODAL_OPEN_ACTION){
      handleOpen()
    } else if (!modalData || (modalData && modalData.action === MODAL_CLOSE_ACTION)){
      handleClose()
    }
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseModal = () => {
    // console.log('handleCloseModal')
    dispatch(openModalAction(null, DELETE_MODAL_DATA));
    dispatch(clearDataForKey())
  };
// console.log('modalData: ', modalData)
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Key id: {modalData && modalData.data && modalData.data.id}</h2>
      <button type="button"
        style={{position:'absolute', top:16, right:16, padding:4}}
        onClick={handleCloseModal}
      >
        Закрыть
      </button>
      {/*<p id="simple-modal-description">*/}
      {/*  Дата последнего использования: {modalData && modalData.data && moment(modalData.data.last_request).format("DD MMMM YYYY")}*/}
      {/*  /!*Последний вход: {modalData && modalData.data && moment.unix(modalData.data.last_request).format("DD MMMM YYYY")}*!/*/}
      {/*</p>*/}
      <LineChart dataId={modalData && modalData.data && modalData.data.id}/>
      {/*<LineChartRecharts />*/}
    </div>
  );
if (!modalData || !modalData.data) return null
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalData && modalData.data && body}
      </Modal>
    </div>
  );

}
const mapStateToProps = (state) => {
  return {
    modalData: state.modalData,
    dispatch: state.dispatch
  };
};
export default connect(mapStateToProps)(SimpleModal);
