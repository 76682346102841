import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from '../../components/Logo';
import { connect } from 'react-redux';
import { logout } from '../../store/actions';
import AppsIcon from '@material-ui/icons/Apps';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 40,
    height: 40
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  usersSummary,
  servicesSummary,
  dispatch,
  ...rest
}) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  let Global = typeof window !== 'undefined' ? window : global
  let localStorage = Global.localStorage

  const handleLogout =() => {
    dispatch(logout())
    console.log('logout')
    localStorage.setItem("authkey", null)
    localStorage.setItem("key", null)
    localStorage.clear()
    window.location.pathname = "/login"
    return <RouterLink to="/login" />
  }
  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
          <Logo />
        <div style={{
          marginLeft:24, fontSize: 24,
        }}>Мониторинг</div>
        <div style={{marginLeft: 24, display:'flex'}}>
          <VpnKeyIcon />
          <div style={{marginLeft: 8, fontSize:22}}>{(usersSummary && usersSummary.data) ? usersSummary.data.online_ids.length : null}</div>
          {(usersSummary && usersSummary.data)?<div style={{marginLeft: 8, fontSize:16}}>({(Number(usersSummary.data.offline_ids.length)+Number(usersSummary.data.online_ids.length))})</div>: null}
          <AppsIcon style={{marginLeft: 24}} />
          <div style={{marginLeft: 8, fontSize:22}}>{(servicesSummary && servicesSummary.data) ? servicesSummary.data.online_ids.length : null}</div>
        </div>

        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit">
            <InputIcon style={{color:'red'}}
                       onClick={()=>{handleLogout()}}
            />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};
const mapStateToProps = state => {
  return {
    usersSummary: state.usersSummary,
    servicesSummary: state.servicesSummary,
    dispatch: state.dispatch
  };
};
export default connect(mapStateToProps)(TopBar);
