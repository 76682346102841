/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-multi-assign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader, Paper,
  Divider, InputLabel, MenuItem, Select, TextField, Menu, Popper
} from '@material-ui/core';
import { filter, } from 'lodash';
import { connect, useSelector } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import Button from '@material-ui/core/Button';
import {
  Button as ButtonSemantic, Form, Icon, Input, Label,
  Loader, Segment
} from 'semantic-ui-react';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import SearchIcon from '@material-ui/icons/Search';

import moment from 'moment';

import makeStyles from '@material-ui/core/styles/makeStyles';
// import ExcelJS from 'exceljs';
import { CSVLink } from 'react-csv';
// import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Autocomplete } from '@material-ui/lab';
import { SearchResult } from './SearchResult/SearchResult';
import { OnlineUsers } from './OnlineUsers/OnlineUsers';
// import SwitchFixDate from './DateRange/SwitchFixDate';
import {
  // actionAddIdCheckedUser,
  // actionFindUsersKeysServicesTree,
  actionFindUsersKeysServicesTreeErase,
  checkTree,
  // getDataForKey,
  loadingApp,
  serchABC,
  // LoadingStatus,
  setActiveUsersDateRange,
  // setActiveUsersFlag,
  // setAppLoadingStatus,
  setComponentLoadingStatus
} from '../../../store/actions';
import {
  KEY_HISTORY,
  // LOADED,
  LOADING,
  // TIME_UPDATE,
  TIME_UPDATE_USERS_TREE,
  USERS_TREE_ACTIVE_BY_DATE,
  DATE_RANGE, NAME, USER_ID, SERVICE_ID
} from '../../../constants';
import {
  // getActiveUsers,
  // getActiveUsersUsingKeyHistory,
  // getKeyHistory,
  // getKeys,
  // getServices,
  getServicesIdsState,
  getUsers,
  getUsersIdsState,
  getKeysByActivityFillUsersServicesSocket,
  findUsersKeysServicesTree,
  // findManyUsersKeysServicesTree,
  findUsersKeysServicesWithKeyHistoryTree,
  // getSearchDb
} from '../../../store/actions/socketActions';
import SimpleModal from '../../../components/modal/Modal';
import PopoverBlock from './PopoverBlock';
import SimplePopover from './PopoverBlock';
// import ContentUsersTree from './ContentUsersTree';
moment.locale('ru');
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));
let handlerInterval;
const getActiveUsersWithUpdate = (props) => {
  // getActiveUsersUsingKeyHistory(props)
  // console.log('interval: 9');
  getKeysByActivityFillUsersServicesSocket(props);
  getUsersIdsState(props);
  getServicesIdsState(props);
  clearInterval(handlerInterval);
  handlerInterval = setInterval(() => {
    getKeysByActivityFillUsersServicesSocket(props);
    getUsersIdsState(props);
    getServicesIdsState(props);
    // getActiveUsersUsingKeyHistory(props)
  }, TIME_UPDATE_USERS_TREE);
  // console.log('handle interval: ', handlerInterval);
};

const UsersTree = ({
  dispatch, activeUsersDateRange, usersWithServicesWithKeys, pageNumberUsersTree, className, appStatus, appL, appLoadingStatus,
  activeUsersFlag, socketConnection, authkey, rightskey, allUsers, allUsersId, allUsersProperty, activeIntervalTime, rightssearch, rightstree,
  ...rest
}) => {
  const classes = useStyles();
  // const theme = useTheme();
  const hasMounted = useRef(false);
  const [loaded, setLoaded] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loadingComponentStatus, setLoadingComponentStatus] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);

  const mode = ['DEFAULT', 'ACTIVE_USERS', 'ACTIVE_USERS_BY_DATE'];
  const [usersTreeMode, setUsersTreeMode] = useState(mode[0]);
  const [treeLoaderStatus, setTreeLoaderStatus] = useState(null);
  const [activeUsersDateRangeFixValue, setActiveUsersDateRangeFixValue] = useState(false);
  const Global = typeof window !== 'undefined' ? window : global;
  const { localStorage } = Global;
  const authkeyStorage = localStorage.getItem('authkey');
  const keyStorage = localStorage.getItem('key');
  // const [age, setAge] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [newStartDate, setNewStartDate] = useState(null);
  const [newEndDate, setNewEndDate] = useState(null);

  // const [newStartDate, setNewStartDate] = useState(new Date().getTime() - 86400000);
  // const [newEndDate, setNewEndDate] = useState(new Date());
  const [newInterval, setNewInterval] = useState(DATE_RANGE.D1);

  const inputEl = useRef(null);
  // const [age, setAge] = useState('');
  const [lastTime, setLastTime] = useState('');

  const fetchOptions = {
    authkey: authkey || authkeyStorage, key: rightskey || keyStorage,
  };

  useEffect(() => {
    const Global = typeof window !== 'undefined' ? window : global;
    const { localStorage } = Global;
    const interval = localStorage.getItem('interval');
    const authkeyStorage = localStorage.getItem('authkey');
    const keyStorage = localStorage.getItem('key');
    const fetchOptions = {
      authkey: authkeyStorage, key: keyStorage,
    };
    const dateEnd = new Date().getTime();

    // console.log('interval: ', interval);
    // console.log('firstLoad: ', firstLoad);
    if (socketConnection && interval && firstLoad) {
      switch (+interval) {
        case 900_000:
          // console.log('case 900_000');
          dispatch(setActiveUsersDateRange({ fixRange: DATE_RANGE.m15 }));
          break;
        case 3_600_000:
          dispatch(setActiveUsersDateRange({ fixRange: DATE_RANGE.H1 }));
          break;
        case 86_400_000:
          dispatch(setActiveUsersDateRange({ fixRange: DATE_RANGE.D1 }));
          break;
        case 604_800_000:
          dispatch(setActiveUsersDateRange({ fixRange: DATE_RANGE.W1 }));
          break;
        case 2_592_000_000:
          dispatch(setActiveUsersDateRange({ fixRange: DATE_RANGE.M1 }));
          break;
        case 7_776_000_000:
          dispatch(setActiveUsersDateRange({ fixRange: DATE_RANGE.M3 }));
          break;
        default:
          break;
      }
      const dateStart = dateEnd - interval;
      // console.log('interval: 0');
      getActiveUsersWithUpdate({
        socketConnection, fetchOptions, page: 1, limit: 10, from: dateStart, to: dateEnd, interval
      });
      findUsersKeysServicesTree({
        socketConnection, fetchOptions, from: dateStart, to: dateEnd
      });
      setFirstLoad(false);
    }
  }, [socketConnection, selectedDate]);
  useEffect(() => {
    if ((socketConnection && socketConnection.connectionState === 1) && !loaded) {
      getUsers({
        socketConnection, fetchOptions, page: 1, limit: 10
      });
      setLoaded(true);
    }
  });

  useEffect(() => {
    if (allUsers && allUsers.length > 0) {
      // getKeys({socketConnection, fetchOptions, user_ids:allUsersId})
      // getServices({socketConnection, fetchOptions})
      getUsersIdsState({ socketConnection, fetchOptions });
      getServicesIdsState({ socketConnection, fetchOptions });
    }
    if (socketConnection) {
      getKeysByActivityFillUsersServicesSocket({ socketConnection, fetchOptions, activeIntervalTime }); // TODO
    }
  }, [allUsers, allUsersId, socketConnection, activeIntervalTime]);

  const [arrayUsers, setArrayUsers] = useState([]);
  const [checkedActiveUsers, setCheckedActiveUsers] = useState(activeUsersFlag);

  // Переключение флаг на "активные"
  useEffect(() => {
    if ((socketConnection && socketConnection.connectionState === 1)) {
      if (activeUsersFlag) {
        dispatch(loadingApp(LOADING));
        // getActiveUsers({socketConnection, fetchOptions, page:1, limit:10})
        // getActiveUsersWithUpdate({socketConnection, fetchOptions, page:1, limit:10, from:(allUsersProperty.from ? allUsersProperty.from : Date.parse(new Date()) - 604800000 ), to:Date.parse(new Date())})
        // getActiveUsersUsingKeyHistory({socketConnection, fetchOptions, page:1, limit:10, from:Date.parse(new Date()) - 604800000 , to:Date.parse(new Date())})
      } else {
        // getUsers({socketConnection, fetchOptions, page:1, limit:10})
      }
    }
  }, [checkedActiveUsers]);
  const [checkLengthNewUsers, setCheckLengthNewUsers] = useState(false);

  const handleFilterInput = (e) => {
    const arr = filter(usersWithServicesWithKeys, (item) => {
      return item.name && item.name.includes(e.target.value);
    });
    setArrayUsers(arr);
    if (!arr.length) {
      setCheckLengthNewUsers(true);
    } else {
      setCheckLengthNewUsers(true);
    }
  };

  const handleSubmit = (date) => {
    setLoadingComponentStatus(true);
    setUsersTreeMode(mode[2]);
    dispatch(setActiveUsersDateRange({ fixRange: null }));
    dispatch(setComponentLoadingStatus({ componentName: KEY_HISTORY, loadingStatus: LOADING }));
    date.preventDefault();
    const start = date.currentTarget[0];
    const end = date.currentTarget[1];
    const dataId = date.currentTarget[2];
    const timeStampStart = Date.parse(moment(new Date(start.value)));
    const timeStampEnd = Date.parse(moment(new Date(end.value)));

    const Global = typeof window !== 'undefined' ? window : global;
    const { localStorage } = Global;
    const authkeyStorage = localStorage.getItem('authkey');
    const keyStorage = localStorage.getItem('key');

    const fetchOptions = {
      authkey: authkeyStorage, key: keyStorage,
    };
    // console.log('interval: 1');
    getActiveUsersWithUpdate({
      socketConnection, fetchOptions, page: 1, limit: 10, from: timeStampStart, to: timeStampEnd
    });
    // getActiveUsersWithUpdate({socketConnection, fetchOptions, page:1, limit:10, from:timeStampStart , to:timeStampEnd})
    // getActiveUsersUsingKeyHistory({socketConnection, fetchOptions, page:1, limit:10, from:timeStampStart , to:timeStampEnd})
    // Установка статуса загрузки данных в компонент
    dispatch(setComponentLoadingStatus({ componentName: USERS_TREE_ACTIVE_BY_DATE, loadingStatus: LOADING }));
  };

  // let loaderStatus

  useEffect(() => {
    if (appLoadingStatus.USERS_TREE_ACTIVE_BY_DATE && appLoadingStatus.USERS_TREE_ACTIVE_BY_DATE.loadingStatus) {
      setTreeLoaderStatus(appLoadingStatus && appLoadingStatus.USERS_TREE_ACTIVE_BY_DATE && appLoadingStatus.USERS_TREE_ACTIVE_BY_DATE.loadingStatus);
    }
  }, [appL]);

  const handleButtonFixRange = (range) => {
    // dispatch(setComponentLoadingStatus({componentName: KEY_HISTORY, loadingStatus: LOADING}))
    // dispatch(setComponentLoadingStatus({componentName: USERS_TREE_ACTIVE_BY_DATE, loadingStatus: LOADING}))
    let dateStart;
    let dateInterval;

    switch (range) {
      case DATE_RANGE.m15:
        dateStart = dateInterval = 1000 * 60 * 15;
        break;
      case DATE_RANGE.H1:
        dateStart = dateInterval = 1000 * 60 * 60;
        break;
      case DATE_RANGE.D1:
        dateStart = dateInterval = 1000 * 60 * 60 * 24;
        break;
      case DATE_RANGE.W1:
        dateStart = dateInterval = 1000 * 60 * 60 * 24 * 7;
        break;
      case DATE_RANGE.M1:
        dateStart = dateInterval = 1000 * 60 * 60 * 24 * 30;
        break;
      case DATE_RANGE.M3:
        dateStart = dateInterval = 1000 * 60 * 60 * 24 * 90;
        break;
      case DATE_RANGE.M6:
        dateStart = dateInterval = 1000 * 60 * 60 * 24 * 180;
        break;
      case DATE_RANGE.M12:
        dateStart = dateInterval = 1000 * 60 * 60 * 24 * 365;
        break;
      default:
        dateStart = dateInterval = 0;
    }

    // console.log(date, 'ageage');
    let dateEnd = null;
    // console.log("поиск2", range);
    // console.log("dateEnd, dateStart, dateInterval", moment(date).startOf('day'));
    if (!range) {
      dateStart = new Date(moment(newStartDate).startOf('minute')).getTime();
      dateEnd = new Date(moment(newEndDate).startOf('minute')).getTime();
      // dateStart = dateEnd - (1000 * 60 * 60 * 24);
      dateInterval = dateEnd - dateStart;// 1000 * 60 * 60 * 24;
      // console.log(dateEnd, dateStart, dateInterval, moment(date).startOf('day'));
      // dateEnd = dateStart + dateInterval;
      // dateInterval = (dateEnd - dateStart) < 0 ? 0 : (dateEnd - dateStart);
      // range = 'D1';
      // console.log("поиск25", new Date(dateStart), new Date(dateEnd), dateInterval / 60 / 1000);

      //  console.log(dateInterval, "ageage");
      // moment(selectedDate).format("X")
    } else {
      // if (dateEnd == null) {
      dateEnd = new Date(moment(newEndDate).startOf('minute')).getTime();
      setNewStartDate(new Date(moment(dateEnd - dateInterval)));
      // dateEnd = new Date().getTime();
      // }
      dateStart = dateEnd - dateStart;
      // setSelectedDate(moment(dateStart));
    }

    // dateEnd = new Date().getTime()
    // dateStart = dateEnd - dateStart

    // console.log('dateStart, dateEnddateStart, dateEnd');

    dispatch(setActiveUsersDateRange({ dateStart, dateEnd, fixRange: range }));
    const Global = typeof window !== 'undefined' ? window : global;
    const { localStorage } = Global;
    localStorage.setItem('interval', dateInterval);
    const authkeyStorage = localStorage.getItem('authkey');
    const keyStorage = localStorage.getItem('key');
    const fetchOptions = {
      authkey: authkeyStorage, key: keyStorage,
    };
    const interval = { from: dateStart, to: dateEnd };
    // console.log('interval: 2');
    // getKeysByActivityFillUsersServicesSocket({socketConnection, fetchOptions, interval})
    getActiveUsersWithUpdate({
      socketConnection, fetchOptions, page: 1, limit: 10, from: dateStart, to: dateEnd, interval
    });
    // console.log('range: ', range)
  };

  const handleChange = (value) => {
    // console.log(event.target.value);
    setNewInterval(value);
    setSelectedDate(new Date());
    if (usersSearch.length) {
      handleSubmitSearch(null, null, value)
    } else {
      handleButtonFixRange(value);
    }
    //

  };

  const handleDateChange = (e) => {
    // console.log(e.target.value, '123123');
    // setNewInterval(DATE_RANGE.D1);
    if (e.target.id === 'datetime-local-start') {
      // console.log(e.target.value, '123123');
      setNewStartDate(e.target.value);
    } else {
      // console.log(e.target.value, '6666');
      setNewEndDate(e.target.value);
    }
    // handleButtonFixRange(0, e.target.value);
    setSelectedDate(e.target.value);
  };

  useEffect(() => {

    if (newEndDate || newStartDate) {

      handleButtonFixRange(0, newEndDate);
    }

  }, [newStartDate, newEndDate,selectedDate])

  const dateButtonFixRange = () => {

    return (
      <div style={{ display: 'flex' }}>

        <FormControl className={classes.formControl} style={{ margin: 0 }}>
          <InputLabel id="demo-simple-select-outlined-label">Период</InputLabel>
          <Select
            // disabled={showSearch}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={newInterval}
            onChange={(event) => handleChange(event.target.value)}
            label="Период"
          >
            <MenuItem value={DATE_RANGE.m15}>15 мин</MenuItem>
            <MenuItem value={DATE_RANGE.H1}>1 час</MenuItem>
            <MenuItem value={DATE_RANGE.D1}>1 день</MenuItem>

            <MenuItem value={DATE_RANGE.W1}>1 неделя</MenuItem>

            <MenuItem value={DATE_RANGE.M1}>1 месяц</MenuItem>
            <MenuItem value={DATE_RANGE.M3}>3 месяца</MenuItem>
            <MenuItem value={DATE_RANGE.M6}>6 месяцев</MenuItem>

            <MenuItem value={DATE_RANGE.M12}>1 год</MenuItem>
          </Select>
        </FormControl>

        <form
          // className={classes.container}
          noValidate
          // style={{
          //   marginTop: 24, flexWrap: 'nowrap', alignItems: 'center', backgroundColor: 'lavender', padding: 16
          // }}
          onSubmit={handleSubmit}
        >
          <TextField
            id="datetime-local-start"
            label="Дата начала"
            type="datetime-local"
            value={newStartDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => handleDateChange(e)}
          />
          <TextField
            id="datetime-local-end"
            label="Дата окончания"
            type="datetime-local"
            value={newEndDate}
            className={classes.textField}
            onChange={(e) => handleDateChange(e)}
            InputLabelProps={{
              shrink: true,
            }}
          />

        </form>

      </div>
    );
  };
  const SwitchFixDate = ({ dispatch, activeUsersDateRange }) => {
    return (
      <div style={{ textAlign: 'right' }}>
        <span style={{ marginRight: 16 }}>Календарь</span>
        <FormControlLabel
          control={(
            <Switch
              color="primary"
              checked={activeUsersDateRange.fixRange}
              onChange={(event) => {
                dispatch(setActiveUsersDateRange({ fixRange: !activeUsersDateRange.fixRange }));
                // setActiveUsersDateRangeFixValue(!activeUsersDateRangeFixValue)
              }}
            />
          )}
          label="Фиксированные интервалы"
          labelPlacement="end"
        />
      </div>
    );
  };

  const dateComponent = () => {
    return (
      <div style={{ flexDirection: 'column', marginRight: 24, alignItems: 'center' }}>

        <br />
        <div style={{ textAlign: 'center' }}>{dateButtonFixRange()}</div>
        {/* {SwitchFixDate({dispatch, activeUsersDateRange})} */}
      </div>

    );
  };

  const forTree = usersWithServicesWithKeys.splice(pageNumberUsersTree, 10);
  const [nameSearch, setNameSearch] = useState('');
  const [usersSearch, setUsersSearch] = useState([]);
  const [userSearch, setUserSearch] = useState('');
  const [serviceSearch, setServiceSearch] = useState('');
  const [search, setSearch] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [searchName, setSearchName] = useState([]);

  const handleSearchName = (e, type) => {
    if (type === NAME) {
      const val = e.target.value;

      localStorage.setItem('tim', new Date().getTime());

      const timer = setTimeout(() => {
        if (val.length > 1 && new Date() - localStorage.getItem('tim') > 1000) {
          // console.log(e.currentTarget, "12312312");
          setAnchorEl(e.currentTarget);
          setOpenAutocomplete(true);

          dispatch(serchABC(val));
        } else {
          clearTimeout(timer);
        }
      }, 1000);
    }
    // console.log(e.target.value, type, "e.target.value");
    // setNameSearch(e.target.value);
    handleSearch(e.target.value, type);

  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSearch = (value, type) => {
    setSearch({ value, type });
    switch (type) {
      case NAME:
        setNameSearch(value);
        break;
      case SERVICE_ID:
        setServiceSearch(value);
        break;
      case USER_ID:
        setUserSearch(value);
        break;
    }
  };

  const handleSubmitSearchErase = () => {
    setNameSearch('');
    setServiceSearch('');
    setUserSearch('');
    setUsersSearch([]);
    setSearch(null);
    setShowSearch(false);
    dispatch(actionFindUsersKeysServicesTreeErase());
  };

  const handleSubmitSearchName = (text, object) => {

    if (object) {
      dispatch(checkTree(object.id * 10000 + object.lib));
    }

  };



  const handleSubmitSearch = (text, object, renge) => {
    // console.log('activeIntervalTime.from: ', new Date(activeIntervalTime.from));
    // console.log('activeIntervalTime.to: ', new Date(activeIntervalTime.to));
    let dateStart;    
    switch (activeUsersDateRange.fixRange) {
      case DATE_RANGE.m15:
        dateStart = 1000 * 60 * 15;
        break;
      case DATE_RANGE.H1:
        dateStart = 1000 * 60 * 60;
        break;
      case DATE_RANGE.D1:
        dateStart = 1000 * 60 * 60 * 24;
        break;
      case DATE_RANGE.W1:
        dateStart = 1000 * 60 * 60 * 24 * 7;
        break;
      case DATE_RANGE.M1:
        dateStart = 1000 * 60 * 60 * 24 * 30;
        break;
      case DATE_RANGE.M3:
        dateStart = 1000 * 60 * 60 * 24 * 90;
        break;
      case DATE_RANGE.M6:
        dateStart = 1000 * 60 * 60 * 24 * 180;
        break;
      case DATE_RANGE.M12:
        dateStart = 1000 * 60 * 60 * 24 * 365;
        break;
      default: dateStart = 0;
    }
    if (renge) {
      // console.log(renge, "rengerenge");
      switch (renge) {
        case DATE_RANGE.m15:
          dateStart = 1000 * 60 * 15;
          break;
        case DATE_RANGE.H1:
          dateStart = 1000 * 60 * 60;
          break;
        case DATE_RANGE.D1:
          dateStart = 1000 * 60 * 60 * 24;
          break;
        case DATE_RANGE.W1:
          dateStart = 1000 * 60 * 60 * 24 * 7;
          break;
        case DATE_RANGE.M1:
          dateStart = 1000 * 60 * 60 * 24 * 30;
          break;
        case DATE_RANGE.M3:
          dateStart = 1000 * 60 * 60 * 24 * 90;
          break;
        case DATE_RANGE.M6:
          dateStart = 1000 * 60 * 60 * 24 * 180;
          break;
        case DATE_RANGE.M12:
          dateStart = 1000 * 60 * 60 * 24 * 365;
          break;
        default:
          dateStart = 0;
      }
    }
    // console.log("поиск", renge);



    // if (dateStart === 0) {
    //   dateStart = 1000 * 60 * 60 * 24 * 7;
    //   // console.log("поиск");
    //   // handleChange(DATE_RANGE.W1);
    // }
    let dateEnd = new Date().getTime();
    dateStart = dateEnd - dateStart;

    if (!renge) {
      dateStart = new Date(moment(newStartDate).startOf('minute')).getTime();
      dateEnd = new Date(moment(newEndDate).startOf('minute')).getTime();
      // dateStart = dateEnd - (1000 * 60 * 60 * 24);
      // dateInterval = dateEnd - dateStart;// 1000 * 60 * 60 * 24;
      // console.log(dateEnd, dateStart, dateInterval, moment(date).startOf('day'));
      // dateEnd = dateStart + dateInterval;
      // dateInterval = (dateEnd - dateStart) < 0 ? 0 : (dateEnd - dateStart);
      // range = 'D1';
      //  console.log(dateInterval, "ageage");
      // moment(selectedDate).format("X")
    } else {
      // if (dateEnd == null) {
      // dateEnd = new Date(moment(newEndDate).startOf('minute')).getTime();
      // setNewStartDate(new Date(moment(dateEnd - dateInterval)));
      // dateEnd = new Date().getTime();
      // }
      // dateStart = dateEnd - dateStart;
      // setSelectedDate(moment(dateStart));
    }

    // console.log(usersSearch, object, usersSearch || object, "usersSearch || Array.isArray(object)");

    if (search && search.value && search.value.length > 0) {
      switch (search.type) {
        case NAME:
          if (usersSearch || Array.isArray(object)) {

            findUsersKeysServicesWithKeyHistoryTree({
              socketConnection, userIds: object?.length ? object : usersSearch, from: dateStart, to: dateEnd
            });
          } else {
            findUsersKeysServicesTree({
            socketConnection, userName: text || search.value, from: dateStart, to: dateEnd
          });
          }

          break;
        case USER_ID:

          // Функционал определения списка Айдишников
          if (search.value.indexOf(',') >= 0) {
            search.value = search.value.trim();
            if (search.value[0] === ',') {
              search.value = search.value.replace(search.value[0], '');
            }
            if (search.value[search.value.length - 1] === ',') { search.value = search.value.replace(search.value[search.value.length - 1], ''); }
            const idsArray = search.value.replace(/\s/g, '').split(',');
            for (let i = 0; i < idsArray.length; i++) {
              idsArray[i] = Number(idsArray[i]);
            }

            findUsersKeysServicesWithKeyHistoryTree({
              socketConnection, userIds: idsArray, from: dateStart, to: dateEnd
            });
          } else {
            findUsersKeysServicesTree({
              socketConnection, userId: search.value, from: dateStart, to: dateEnd
            });
          }

          // Если в поле введен один айдишник
          // findUsersKeysServicesTree({socketConnection, userId:search.value, from:dateStart , to:dateEnd})
          // dispatch(actionAddIdCheckedUser(search.value))
          break;
        case SERVICE_ID:
          findUsersKeysServicesTree({
            socketConnection, serviceId: search.value, from: dateStart, to: dateEnd
          });
          break;
      }
      setShowSearch(true);
    }

    // findUsersKeysServicesTree({socketConnection, userId:search, serviceId:search, userName:search, from:activeIntervalTime.from , to:activeIntervalTime.to})
  };

  // function handleSaveToExcelSearchingData() {
  //   console.log('handleSaveToExcelSearchingData');
  //   // const workbook = new ExcelJS.Workbook();
  //   // const sheet = workbook.addWorksheet('My Sheet');
  //   // sheet.addRow({id: 1, name: 'John Doe', dob: new Date(1970,1,1)});
  //   // workbook.xlsx.writeFile('excel1')
  //   // workbook.xlsx.writeFile('excel1')
  // }
  const UKSTreeState = useSelector((state) => state.findUsersKeysServicesTree);
  const [UKSTree, setUKSTree] = useState(UKSTreeState);

  useEffect(() => {
    setUKSTree(UKSTreeState);
  }, [UKSTreeState]);

  useEffect(() => {

    if (Object.keys(rightstree).length) {
      setUsersSearch(Object.keys(rightstree).map((key) => key.substring(0, key.length - 4)))
      handleSubmitSearch(null, Object.keys(rightstree).map((key) => key.substring(0, key.length - 4)))
    }

  }, [rightstree]);

  // console.log(rightssearch, "rightssearch", openAutocomplete);
  return (
    <Card>
      <CardHeader
        title="Пользователи / сервисы / ключи"
        action={(
          <FormControl component="fieldset" >
            <FormGroup aria-label="position" row>
              <div style={{
                margin: 0, marginRight: 24, border: '1px solid #ccc', borderRadius: 4, backgroundColor: '#eee', padding: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center'
              }}
              >  

                <Form style={{ margin: 0 }}>
                  <Form.Group widths="equal" style={{ margin: 0 }}>
                    <Form.Field  >
                      <TextField ref={inputEl} placeholder="Имя..." onClick={() => setOpenAutocomplete(true)} onChange={(event) => handleSearchName(event, NAME)} value={nameSearch} />



                      {/* <Popper
                        id="simple-menu"
                        anchorEl={inputEl}
                        keepMounted
                        open={openAutocomplete}
                        onClose={() => setOpenAutocomplete(false)}
                      >
                        <Paper>

                          {rightssearch.map((item, key) => <MenuItem key={key} onClick={() => {
                            console.log(item);
                            handleSearch(item?.name, NAME);
                            // setUserSearch(item);
                            setOpenAutocomplete(false);
                            handleSubmitSearchName(item?.name, item);
                          }}>{item.name}</MenuItem>)}

                        </Paper>
                      </Popper> */}
                    </Form.Field>



                    <Form.Field disabled={search && search.type !== USER_ID && search.value?.length > 0}>
                      <TextField placeholder="ID пользователя..." variant="outlined" onChange={(event) => handleSearchName(event, USER_ID)} value={userSearch} />
                      {/* <Input placeholder="ID пользователя..." variant="outlined" onChange={(event) => handleSearchName(event, USER_ID)} value={userSearch} /> */}
                    </Form.Field>
                    <Form.Field disabled={search && search.type !== SERVICE_ID && search.value?.length > 0}>
                      <Input placeholder="ID сервиса..." variant="outlined" onChange={(event) => handleSearchName(event, SERVICE_ID)} value={serviceSearch} />
                    </Form.Field>
                    <Form.Field>
                      <ButtonSemantic
                        animated
                        onClick={() => handleSubmitSearch()}
                        disabled={!search || !search.value || search.value.length < 1}
                      >
                        <ButtonSemantic.Content visible>Поиск</ButtonSemantic.Content>
                        <ButtonSemantic.Content hidden>
                          <Icon name="search" />
                        </ButtonSemantic.Content>
                      </ButtonSemantic>
                    </Form.Field>
                    <Form.Field>
                      <ButtonSemantic
                        animated
                        onClick={() => handleSubmitSearchErase()}
                        disabled={!search || !search.value || search.value.length < 1}
                        color="red"
                      >
                        <ButtonSemantic.Content visible>Х</ButtonSemantic.Content>
                        <ButtonSemantic.Content hidden>
                          <Icon name="erase" />
                        </ButtonSemantic.Content>
                      </ButtonSemantic>
                    </Form.Field>
                  </Form.Group>
                </Form>
              </div>

              {dateComponent()}

            </FormGroup>

          </FormControl>
        )}
      />

      <Divider />
      <CardContent>
        <Box
          height="100%"
          position="relative"
        >
          {UKSTree ? <CSVLink data={UKSTree.users} filename="search_result_to_excel.csv" separator=";">Export</CSVLink> : null}

          {showSearch ? <SearchResult /> : <OnlineUsers />}

        </Box>
      </CardContent>
      <SimpleModal />
      <PopoverBlock placement='bottom-start' open={openAutocomplete} anchorRef={inputEl} content={rightssearch} onClick={(item) => {
        handleSearch(item?.name, NAME);
        setOpenAutocomplete(false);
        handleSubmitSearchName(item?.name, item);
      }} />
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    appL: state.appLoadingStatus && state.appLoadingStatus.USERS_TREE_ACTIVE_BY_DATE && state.appLoadingStatus.USERS_TREE_ACTIVE_BY_DATE.loadingStatus,
    activeIntervalTime: state.activeIntervalTime,
    appLoadingStatus: state.appLoadingStatus,
    appStatus: state.appStatus,
    authkey: state.authkey,
    rightskey: state.rightskey,
    rightssearch: state.rightssearch,
    rightstree: state.rightstree,
    allUsers: state.allUsers,
    allUsersProperty: state.allUsersProperty,
    allUsersId: state.allUsersId,
    activeUsersFlag: state.activeUsersFlag,
    socketConnection: state.socketConnection,
    pageNumberUsersTree: state.pageNumberUsersTree,
    usersWithServicesWithKeys: state.usersWithServicesWithKeys,
    activeUsersDateRange: state.activeUsersDateRange,
    dispatch: state.dispatch
  };
};

export default connect(mapStateToProps)(UsersTree);
