import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles, LinearProgress
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AppsIcon from '@material-ui/icons/Apps';
import { connect } from 'react-redux';
import { getServicesIdsState, getUsersIdsState } from '../../../store/actions/socketActions';
import { TIME_UPDATE } from '../../../constants';
import ModalExampleCloseConfig from '../../../components/modal/ServicesList';
import { Statistic } from 'semantic-ui-react'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.grey[600],
    height: 56,
    width: 56,
    cursor: 'pointer'
  },
  onlineIcon: {
    color: colors.green[900],
    marginRight: 10
  },
  offlineIcon: {
    color: colors.red[900],
    marginRight: 10
  },
  offlineValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
    fontWeight: 900,
    fontSize: 20
  },
  onlineValue: {
    color: colors.blue[900],
    marginRight: theme.spacing(1),
    fontWeight: 900,
    fontSize: 20
  },
  progressUsers: {
    backgroundColor: colors.red[600],
    color: colors.green[600],
    height: 16
  }
}));
let Global = typeof window !== 'undefined' ? window : global;
let localStorage = Global.localStorage;
let authkeyStorage = localStorage.getItem('authkey');
let keyStorage = localStorage.getItem('key');

let fetchOptions = {
  authkey: authkeyStorage, key: keyStorage
};

const Services = ({ className, servicesSummary, socketConnection, ...rest }) => {
  const classes = useStyles();
  let [timeOfUpdateServicesSummary, setTimeOfUpdateServicesSummary] = useState(null);
  let [modal, setModal] = useState(false);
  // useEffect(() => {
  //   if (socketConnection && socketConnection.connectionState === 1 ) {
  //     // getServicesIdsState({ socketConnection, fetchOptions })
  //     // setTimeOfUpdateServicesSummary(Date.parse(new Date()));
  //   }
  // });
  if (!servicesSummary || !servicesSummary.data) {
    return null;
  }
  const toggleElement = () => {
    return <AppsIcon className={classes.avatar} style={{ backgroundColor: 'white' }}
                     onClick={() => {
                       setModal(true);
                     }} />;
  };
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify='space-between'
          spacing={3}
        >
          <Grid item>
            <Statistic label='Сервисы' value={servicesSummary.data.online_ids.length + servicesSummary.data.offline_ids.length} />
          </Grid>
          {/*fgvwv*/}
          <Grid item>
            <Grid style={{display:'flex'}}>

              <ArrowUpwardIcon className={classes.onlineIcon} />
              <Typography
                className={classes.onlineValue}
                variant='body2'
              >
                {servicesSummary.data.online_ids.length}
              </Typography>
              <Typography
                color='textSecondary'
                variant='caption'
                className={classes.onlineValue}
              >
                Используются
              </Typography>
            </Grid>
            <Grid style={{display:'flex'}}>
              <ArrowDownwardIcon className={classes.offlineIcon} />
              <Typography
                className={classes.offlineValue}
                variant='body2'
              >
                {servicesSummary.data.offline_ids.length}
              </Typography>
              <Typography
                color='textSecondary'
                variant='caption'
                className={classes.offlineValue}
              >
                Неактивные
              </Typography>
            </Grid>
          </Grid>

          {/*fvav*/}

          <Grid item>
            <ModalExampleCloseConfig toggleElement={toggleElement()} />
          </Grid>
        </Grid>
      </CardContent>
      <LinearProgress
        value={servicesSummary.data.online_ids.length * 100 / (servicesSummary.data.online_ids.length + servicesSummary.data.offline_ids.length)}
        variant='determinate'
        style={{ backgroundColor: 'red', color: 'green' }}
        className={classes.progressUsers}
      />
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    socketConnection: state.socketConnection,
    servicesSummary: state.servicesSummary,
    dispatch: state.dispatch
  };
};

export default connect(mapStateToProps)(Services);
