export const REQUEST = {

  /*

   */
  GetKeys: 'GetKeys',
  GetKeyHistory: 'GetKeyHistory',
  GetKeyHistoryOld: 'GetKeyHistoryOld',

  GetUsers: 'GetUsers',
  GetActiveUsers: 'GetActiveUsers',
  GetActiveUsersUsingKeyHistory: 'GetActiveUsersUsingKeyHistory',

  GetServices: 'GetServices',
  JoinGroup: 'JoinGroup',

  GetUsersSummary: 'GetUsersSummary',
  GetServicesSummary: 'GetServicesSummary',

  GetUsersIdsState: 'GetUsersIdsState',
  GetServicesIdsState: 'GetServicesIdsState',

  GetUsersServicesByActivity: 'GetUsersServicesByActivity',
  FindUsersKeysServicesTree: 'FindUsersKeysServicesTree',
  FindManyUsersKeysServicesTree: 'FindManyUsersKeysServicesTree',
  FindUsersKeysServicesWithKeyHistoryTree: 'FindUsersKeysServicesWithKeyHistoryTree',
}

export const RESPONSE = {
  ReceiveUsersServices: 'ReceiveUsersServices',
  UpdateUsersServices: 'UpdateUsersServices',
  GetKeysResponse: 'GetKeysResponse',
  GetKeyHistoryOldResponse: 'GetKeyHistoryOldResponse',
  GetKeyHistoryResponse: 'GetKeyHistoryResponse',
  GetUsersResponse: 'GetUsersResponse',
  GetActiveUsersResponse: 'GetActiveUsersResponse',
  GetActiveUsersUsingKeyHistoryResponse : 'GetActiveUsersUsingKeyHistoryResponse',
  GetServicesResponse: 'GetServicesResponse',
  UpdateKeys: 'UpdateKeys',

  GetUsersSummaryResponse: 'GetUsersSummaryResponse',
  GetServicesSummaryResponse: 'GetServicesSummaryResponse',

  GetUsersIdsStateResponse: 'GetUsersIdsStateResponse',
  GetServicesIdsStateResponse: 'GetServicesIdsStateResponse',

  GetUsersServicesByActivityResponse: 'GetUsersServicesByActivityResponse',
  FindUsersKeysServicesTreeResponse: 'FindUsersKeysServicesTreeResponse',
  FindManyUsersKeysServicesTreeResponse: 'FindManyUsersKeysServicesTreeResponse',
  FindUsersKeysServicesWithKeyHistoryTreeResponse: 'FindUsersKeysServicesWithKeyHistoryTreeResponse',

}
