/* eslint-disable no-return-assign */
import {
  CLEAR_CLOSE_MODAL,
  CLEAR_CLOSE_MODAL_SAVE_DATA, CLEAR_DATA_FOR_KEY,
  DELETE_ALERT, DELETE_ID_CHECKED_USER,
  DELETE_MODAL_DATA,
  GET_DATA_FOR_KEY, GET_KEYS_BY_ACTIVITY_FILL_USERS_SERVICES,
  MODAL_CLOSE_ACTION,
  MODAL_OPEN_ACTION,
  PAGINATION_START_ALLUSERS_NUMBER, SET_ACTIVE_INTERVAL_TIME,
  SET_ACTIVE_PIE_CHART_ELEMENT, SET_ACTIVE_USERS_DATE_RANGE,
  SET_ACTIVE_USERS_FLAG,
  SET_ALERT,
  SET_ALL_KEYS,
  SET_ALL_KEYS_ID_ARRAY,
  SET_ALL_SERVICES,
  SET_ALL_USERS,
  SET_ALL_USERS_ID_ARRAY,
  SET_ALL_USERS_PROPERTY,
  SET_ALL_USERS_SERVICES,
  SET_APP_STATUS,
  SET_AUTH,
  SET_AUTHKEY,
  SET_COMPONENT_KEY_HISTORY_APP_STATUS,
  SET_COMPONENT_KEY_HYSTORY_APP_STATUS,
  SET_COMPONENT_KEY_HYSTORY_STATUS, SET_COMPONENT_USERS_TREE_ACTIVE_BY_DATE_APP_STATUS,
  SET_CONNECT,
  SET_DATA_FOR_FIRST_PIE,
  SET_DATA_FOR_SECOND_PIE, SET_FIND_USERS_KEYS_SERVICES_TREE, SET_FIND_USERS_KEYS_SERVICES_TREE_ERASE,
  SET_FIRST_PIE_TOTAL_REQUEST_FLAG, SET_ID_CHECKED_USER,
  SET_KEY,
  SET_SEARCH,
  SET_MODAL_DATA, SET_ONE_ERROR_BY_TAG,
  SET_SERVICES_BY_KEYS_ARRAY,
  SET_SERVICES_PIE_CHART,
  SET_SERVICES_SUMMARY,
  SET_SERVICES_WITH_QTY_KEYS,
  SET_SERVICES_WITH_USERS_WITH_KEYS,
  SET_USERS_SUMMARY,
  SET_USERS_WITH_SERVICES_WITH_KEYS,
  UPDATE_KEYS,
  SET_TREE
} from '../../constants';

export const initialState = {
  appLoadingStatus: {},
  alert: null,
  socketConnection: null,
  authCheck: null, // проверка прав в компонентах
  authkey: null,
  rightskey: null,
  rightssearch: [],
  rightstree: {},
  appStatus: '',
  modalData: null,
  allUsersServices: {},
  dataForFirstPie: null,
  forFirstPieTotalRequestFlag: false,
  dataForSecondPie: null,
  dateForKey: null,
  usersWithServicesWithKeys: [],
  pageNumberUsersTree: 0,
  servicesWithUsersWithKeys: [],
  allUsers: [],
  allUsersProperty: null,
  activeUsersFlag: false,
  allUsersId: [],
  usersSummary: null,
  allServices: [],
  allKeys: [],
  allKeysId: [],
  servicesSummary: null,
  servicesByKeys: [], // Список id сервисов только из списка ключей
  usersList: [],
  activeUsersDateRange: {},
  hoverServicesListElement: [],
  servicesPieChartData: {},
  hoverServicePieChartElement: [],
  serviceLineChartData: [],
  servicesWithQtyKeys: [],
  activePieChartElement: {},
  keysByActivityFillUsers: {},
  activeIntervalTime: { from:(new Date().getTime() - 900_000 ), to:(new Date().getTime()) },
  findUsersKeysServicesTree: null,
  idCheckedUser: [],
  oneError:null
};


export function appLoadingStatus(state = initialState.appLoadingStatus, action) {
  // console.log('action.payload: ' ,action.payload)
  switch (action.type) {
    case SET_COMPONENT_KEY_HISTORY_APP_STATUS:
    case SET_COMPONENT_USERS_TREE_ACTIVE_BY_DATE_APP_STATUS:
      // console.log('SET_COMPONENT_'+action.payload.component+'_APP_STATUS action.payload: ' ,action.payload)
      state[action.payload.componentName] = action.payload
      return state;
    default:
      return state;
  }
}
export function appStatus(state = initialState.appStatus, action) {
  switch (action.type) {
    case SET_APP_STATUS:
      return state = action.payload;
    default:
      return state;
  }
}
export function socketConnection(state = initialState.socketConnection, action) {
  switch (action.type) {
    case SET_CONNECT:
      return state = action.payload;
    default:
      return state;
  }
}
export function authkey(state = initialState.authkey, action) {
  switch (action.type) {
    case SET_AUTHKEY:
      return state = action.payload;
    default:
      return state;
  }
}
export function rightskey(state = initialState.rightskey, action) {
  switch (action.type) {
    case SET_KEY:
      return state = action.payload;
    default:
      return state;
  }
}
export function rightstree(state = initialState.rightstree, action) {
  switch (action.type) {
    case SET_TREE:
      return state = action.payload;
    default:
      return state;
  }
}
export function rightssearch(state = initialState.rightssearch, action) {
  switch (action.type) {
    case SET_SEARCH:
      return state = action.payload;
    default:
      return state;
  }
}
export function alert(state = initialState.alert, action) {
  switch (action.type) {
    case SET_ALERT:
      return state = action.payload;
      case DELETE_ALERT:
      return state = null;
    default:
      return state;
  }
}
export function modalData(state = initialState.modalData, action) {
  switch (action.type) {
    case SET_MODAL_DATA:
      let setData = {}
      setData.data = action.payload
      setData.action = MODAL_OPEN_ACTION
      return state = setData;
    case DELETE_MODAL_DATA:
      let deleteData = {}
      deleteData.data = action.payload
      deleteData.action = MODAL_CLOSE_ACTION
      return deleteData;
    case CLEAR_CLOSE_MODAL:
      console.log('modalData reducer: ', state)
      let saveData = state
      saveData.action = MODAL_CLOSE_ACTION
      return saveData;
    default:
      return state;
  }
}

export function allUsersServices(state = initialState.allUsersServices, action) {
  switch (action.type) {
    case SET_ALL_USERS_SERVICES:
      return state = action.payload;
    default:
      return state;
  }
}
export function usersWithServicesWithKeys(state = initialState.usersWithServicesWithKeys, action) {
  switch (action.type) {
    case SET_USERS_WITH_SERVICES_WITH_KEYS:
      return state = action.payload;
    default:
      return state;
  }
}
export function pageNumberUsersTree(state = initialState.pageNumberUsersTree, action) {
  switch (action.type) {
    case PAGINATION_START_ALLUSERS_NUMBER:
      return state = action.payload;
    default:
      return state;
  }
}
export function activeUsersDateRange(state = initialState.activeUsersDateRange, action) {
  switch (action.type) {
    case SET_ACTIVE_USERS_DATE_RANGE:
      return state = { ...state, ...action.payload };
    default:
      return state;
  }
}
export function servicesWithUsersWithKeys(state = initialState.servicesWithUsersWithKeys, action) {
  switch (action.type) {
    case SET_SERVICES_WITH_USERS_WITH_KEYS:
      return state = action.payload;
    default:
      return state;
  }
}
export function allUsers(state = initialState.allUsers, action) {
  switch (action.type) {
    case SET_ALL_USERS:
      return state = action.payload;
    default:
      return state;
  }
}
export function activeUsersFlag(state = initialState.activeUsersFlag, action) {
  switch (action.type) {
    case SET_ACTIVE_USERS_FLAG:
      return state = action.payload;
    default:
      return state;
  }
}
export function usersSummary(state = initialState.usersSummary, action) {
  switch (action.type) {
    case SET_USERS_SUMMARY:
      return state = action.payload;
    default:
      return state;
  }
}
export function allUsersProperty(state = initialState.allUsersProperty, action) {
  switch (action.type) {
    case SET_ALL_USERS_PROPERTY:
      return state = action.payload;
    default:
      return state;
  }
}
export function allUsersId(state = initialState.allUsersId, action) {
  switch (action.type) {
    case SET_ALL_USERS_ID_ARRAY:
      return state = action.payload;
    default:
      return state;
  }
}
export function allServices(state = initialState.allServices, action) {
  switch (action.type) {
    case SET_ALL_SERVICES:
      return state = action.payload;
    default:
      return state;
  }
}
export function servicesSummary(state = initialState.servicesSummary, action) {
  switch (action.type) {
    case SET_SERVICES_SUMMARY:
      return state = action.payload;
    default:
      return state;
  }
}
export function allKeys(state = initialState.allKeys, action) {
  switch (action.type) {
    case SET_ALL_KEYS:
      return state = action.payload;
    case UPDATE_KEYS:
      // console.log('reducer UPDATE_KEYS: ', action.payload)
      let result = JSON.parse(JSON.stringify(state))
      for (let p = 0; p<action.payload.length; p++){
        let keyMatch = false
        for (let s = 0; s<result.length; s++){
          if (result[s].id === action.payload[p]){
            result[s] = action.payload[p]
            // console.log('---------- update key --------- ', action.payload[p])
            keyMatch = true
          }
        }
        if (!keyMatch){
          result.push(action.payload[p])
          // console.log('---------- add new key --------- ', action.payload[p])
        }
      }

      // console.log('reducer UPDATE_KEYS result: ', result)
      return result
    default:
      return state;
  }
}
export function allKeysId(state = initialState.allKeysId, action) {
  switch (action.type) {
    case SET_ALL_KEYS_ID_ARRAY:
      return state = action.payload;
    default:
      return state;
  }
}
export function servicesByKeys(state = initialState.servicesByKeys, action) {
  switch (action.type) {
    case SET_SERVICES_BY_KEYS_ARRAY:
      return state = action.payload;
    default:
      return state;
  }
}
export function servicesWithQtyKeys(state = initialState.servicesWithQtyKeys, action) {
  switch (action.type) {
    case SET_SERVICES_WITH_QTY_KEYS:
      return state = action.payload;
    default:
      return state;
  }
}
export function activePieChartElement(state = initialState.activePieChartElement, action) {
  switch (action.type) {
    case SET_ACTIVE_PIE_CHART_ELEMENT:
      return state = action.payload;
    default:
      return state;
  }
}

export function usersList(state = initialState.usersList, action) {
  switch (action.type) {

    default:
      return state;
  }
}
// export function servicesList(state = initialState.servicesList, action) {
//   switch (action.type) {
//
//     default:
//       return state;
//   }
// }
export function servicesPieChartData(state = initialState.servicesPieChartData, action) {
  switch (action.type) {
    case SET_SERVICES_PIE_CHART:
      console.log("red spc")
      return state = action.payload;
    default:
      return state;
  }
}
export function hoverServicePieChartElement(state = initialState.hoverServicePieChartElement, action) {
  switch (action.type) {

    default:
      return state;
  }
}
export function serviceLineChartData(state = initialState.serviceLineChartData, action) {
  switch (action.type) {

    default:
      return state;
  }
}
export function hoverServicesListElement(state = initialState.hoverServicesListElement, action) {
  switch (action.type) {

    default:
      return state;
  }
}

export function dataForFirstPie(state = initialState.dataForFirstPie, action) {
  switch (action.type) {
    case SET_DATA_FOR_FIRST_PIE:
      // console.log("SET_DATA_FOR_FIRST_PIE")
      return state = action.payload;
    default:
      return state;
  }
}
export function forFirstPieTotalRequestFlag(state = initialState.forFirstPieTotalRequestFlag, action) {
  switch (action.type) {
    case SET_FIRST_PIE_TOTAL_REQUEST_FLAG:
      return state = action.payload;
    default:
      return state;
  }
}
export function dataForSecondPie(state = initialState.dataForSecondPie, action) {
  switch (action.type) {
    case SET_DATA_FOR_SECOND_PIE:
      // console.log("SET_DATA_FOR_FIRST_PIE")
      return state = action.payload;
    default:
      return state;
  }
}
export function dateForKey(state = initialState.dateForKey, action) {
  switch (action.type) {
    case GET_DATA_FOR_KEY:
      return state = action.payload;
    case CLEAR_DATA_FOR_KEY:
      return state = null
    default:
      return state;
  }
}
export function authCheck(state = initialState.authCheck, action) {
  switch (action.type) {
    case SET_AUTH:
      // console.log("SET_DATA_FOR_FIRST_PIE")
      return state = action.payload;
    default:
      return state;
  }
}
export function keysByActivityFillUsers(state = initialState.keysByActivityFillUsers, action) {
  switch (action.type) {
    case GET_KEYS_BY_ACTIVITY_FILL_USERS_SERVICES:
      return state = action.payload;
    default:
      return state;
  }
}
export function activeIntervalTime(state = initialState.activeIntervalTime, action) {
  switch (action.type) {
    case SET_ACTIVE_INTERVAL_TIME:
      return state = action.payload;
    default:
      return state;
  }
}
export function findUsersKeysServicesTree (state = initialState.findUsersKeysServicesTree, action) {
  switch (action.type) {
    case SET_FIND_USERS_KEYS_SERVICES_TREE:
      return state = action.payload;
    case SET_FIND_USERS_KEYS_SERVICES_TREE_ERASE:
      return state = null
    default:
      return state;
  }
}
export function idCheckedUser(state = initialState.idCheckedUser, action) {
  switch (action.type) {
    case SET_ID_CHECKED_USER:
        return state.concat(action.payload);
    case DELETE_ID_CHECKED_USER:
      state.splice(state.indexOf(action.payload),1)
      return state
    default:
      return state;
  }
}
export function oneError(state = initialState.oneError, action) {
  switch (action.type) {
    case SET_ONE_ERROR_BY_TAG:
      return state = action.payload;
    default:
      return state;
  }
}

