import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';
import { auth, registration } from '../../store/actions';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const RegisterView = ({dispatch}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  let handleOnSubmit = (data)=>{
    console.log("handleOnSubmit data: ", data)
    dispatch(registration(data))
    // navigate('/app/dashboard', { replace: true });
  }

  return (
    <Page
      className={classes.root}
      title="Регистрация"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              firstName: '',
              lastName: '',
              password: '',
              policy: false
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string().email('Введите корректный email').max(255).required('Email обязателен'),
                firstName: Yup.string().max(255).required('Имя обязательно'),
                lastName: Yup.string().max(255).required('Фамилия обязательно'),
                password: Yup.string().max(255).required('Пароль обязательно'),
                // policy: Yup.boolean().oneOf([true], 'This field must be checked')
              })
            }
            onSubmit={(values) => {
              handleOnSubmit(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={(e)=> {e.preventDefault();handleSubmit()}}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Регистрация
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Используйте свой email для регистрации
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label="Имя"
                  margin="normal"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  label="Фамилия"
                  margin="normal"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Пароль"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                {/*<Box*/}
                {/*  alignItems="center"*/}
                {/*  display="flex"*/}
                {/*  ml={-1}*/}
                {/*>*/}
                {/*  <Checkbox*/}
                {/*    checked={values.policy}*/}
                {/*    name="policy"*/}
                {/*    onChange={handleChange}*/}
                {/*  />*/}
                  {/*<Typography*/}
                  {/*  color="textSecondary"*/}
                  {/*  variant="body1"*/}
                  {/*>*/}
                  {/*  I have read the*/}
                  {/*  {' '}*/}
                  {/*  <Link*/}
                  {/*    color="primary"*/}
                  {/*    component={RouterLink}*/}
                  {/*    to="#"*/}
                  {/*    underline="always"*/}
                  {/*    variant="h6"*/}
                  {/*  >*/}
                  {/*    Terms and Conditions*/}
                  {/*  </Link>*/}
                  {/*</Typography>*/}
                {/*</Box>*/}
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Зарегистрировать аккаунт
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Уже есть аккаунт?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Войти
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    // alert: state.alert,
    // authkey: state.authkey,
    // rightskey: state.rightskey,
    dispatch: state.dispatch
  };
};
export default connect(mapStateToProps)(RegisterView);
