import React, { useState } from 'react';
import { Icon, Table } from 'semantic-ui-react'
import JSONInput from 'react-json-editor-ajrm';

const ErrorTable = (props) => {

  console.log('props: ', props.props);
  const {activeDateShowErrors, arrayDateWithErrors} = props.props
  const [dataForTable, setDataForTable] = useState(arrayDateWithErrors[activeDateShowErrors])

  console.log('dataForTable: ', dataForTable);
  return (
    <Table celled>
      <Table.Header>
        <Table.Row style={{width: '100%'}}>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Service code</Table.HeaderCell>
          <Table.HeaderCell>Webserver code</Table.HeaderCell>
          <Table.HeaderCell>Request</Table.HeaderCell>
          <Table.HeaderCell>Response</Table.HeaderCell>
          <Table.HeaderCell>Url</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body className='my'>
       {dataForTable ? dataForTable.map((data) => CustomRow(data)) : null}
      </Table.Body>
    </Table>
  )
}
const CustomRow = (row) => {

  return (
    <Table.Row key={row.id}>
      <Table.Cell>{row.name}</Table.Cell>
      <Table.Cell>{row.service_code}</Table.Cell>
      <Table.Cell>{row.webserver_code}</Table.Cell>
      <Table.Cell><p style={{width:400, overflowY:'auto'}}>{
        <JSONInput
          id='a_unique_id'
          placeholder={row.request}
          // colors      = { darktheme }
          theme="light_mitsuketa_tribute"
          // theme="dark_vscode_tribute"
          // locale={locale}
          height='100%'
          width ='100%'
          // onChange={(e) => {
          //   handleJsonEditor(e)
          // }}
        />
      }</p></Table.Cell>
      <Table.Cell><p style={{width:400, overflowY:'auto'}}>{<JSONInput
        id='a_unique_id'
        placeholder={row.response}
        // colors      = { darktheme }
        theme="light_mitsuketa_tribute"
        // theme="dark_vscode_tribute"
        // locale={locale}
        height='100%'
        width ='100%'
        // onChange={(e) => {
        //   handleJsonEditor(e)
        // }}
      />}</p></Table.Cell>
      <Table.Cell><div>
        <div>{row.url}</div>
        <div>{new Date(Number(row.time)).toString()}</div>
      </div></Table.Cell>
    </Table.Row>
  )
}


export default ErrorTable
