import React, { useEffect, useState } from 'react';
import { getErrorFromPath } from '../../_helpers';
import { oneError } from '../../store/reducers/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { setOneError } from '../../store/actions';
import JSONInput from 'react-json-editor-ajrm';
import ApiCheckDashboard from './ApiCheckDashboard';

const ApiCheck = () => {
  const dispatch = useDispatch()
  const oneErrorFromStore = useSelector(state => state.oneError)
  const [oneError, setOneErrorState] = useState(oneErrorFromStore)
  // useEffect(()=> {
  //   getErrorFromPath({dispatch, func: setOneError})
  // })
  useEffect(async ()=>{
    if (!oneErrorFromStore){
      let error = await getErrorFromPath()
          console.log('error', error)
      if (error){
        setOneErrorState(error.data)
      }
    }


  },[])


  if (!window.location.search){
    return <ApiCheckDashboard />
  }
  console.log('State oneError', oneError)
  if (!oneError){
    return null
  }
  return (
    <>
      <div>ApiCheck</div>
      <div>error: {oneError.status}</div>
      <div>
        <JSONInput
          id='a_unique_id'
          placeholder={oneError.message}
          // colors      = { darktheme }
          theme="light_mitsuketa_tribute"
          // theme="dark_vscode_tribute"
          // locale={locale}
          height='100%'
          width ='100%'
          // onChange={(e) => {
          //   handleJsonEditor(e)
          // }}
        />
      </div>
    </>
  )
}
export default ApiCheck
