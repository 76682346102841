import React from 'react';
import TimelineIcon from '@material-ui/icons/Timeline';
import { Avatar, colors, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.blue[600],
    height: 40,
    width: 40
  },
  onlineIcon: {
    color: colors.green[900],
    marginRight: 10
  },
  offlineIcon: {
    color: colors.red[900],
    marginRight: 10
  },
  offlineValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  },
  onlineValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  },
}));

const Logo = (props) => {
  const classes = useStyles();
  return (
    <Avatar className={classes.avatar}>
      <TimelineIcon />
    </Avatar>
  );
};

export default Logo;
