import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { getKeyHistory } from '../../../../store/actions/socketActions';
import { actionAddIdCheckedUser, actionDeleteIdCheckedUser, openModalAction } from '../../../../store/actions';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import AddBoxIcon from '@material-ui/icons/AddBox';

export const SearchResult = () => {
  const findUsersKeysServicesTree = useSelector(state=>state.findUsersKeysServicesTree)
  const activeIntervalTime = useSelector(state=>state.activeIntervalTime)
  const socketConnection = useSelector(state=>state.socketConnection)

  const forceUpdate = React.useCallback(() => setIdCheckedUser({}), []);

  const idCheckedUser = useSelector(state=>state.idCheckedUser)
  const [idCheckedUserState, setIdCheckedUser] = useState(idCheckedUser)
  useEffect(()=>{
    setIdCheckedUser(idCheckedUser)
  }, [idCheckedUser, idCheckedUser.length])

  let Global = typeof window !== 'undefined' ? window : global
  let localStorage = Global.localStorage
  let authkeyStorage = localStorage.getItem('authkey');
  let keyStorage = localStorage.getItem('key');
  const dispatch = useDispatch()
  let fetchOptions = {
    authkey: authkeyStorage, key: keyStorage,
  }
  useEffect(()=>{

  })
  useEffect(()=>{

  }, [findUsersKeysServicesTree])
  if (!findUsersKeysServicesTree || !findUsersKeysServicesTree.users || findUsersKeysServicesTree.users.length == 0){
    return null
  }

  const handleClickUsersServices = (id) => {
    if (idCheckedUser.includes(id)){
      dispatch(actionDeleteIdCheckedUser(id))
      forceUpdate()
    }else {
      dispatch(actionAddIdCheckedUser(id))
    }
  }

  const User =(user)=> {
    // console.log('User user: ', user)
    let allServicesRequests = 0
    let allServicesRequestsQty = user.services.length
    if (user.services){
      for (let i=0; i<user.services.length; i++){
        allServicesRequests += user.services[i].selectedRequests
      }
    }

    return(
      <CSSTransition
        in={true}
        timeout={300}
        classNames="alert"
        unmountOnExit
        key={user.id}
      >
        <div style={{display:'flex', flexDirection:'row', backgroundColor:'#F4F6F8', marginBottom:4, width:'100%'}}>
          <div style={{display:'flex', flexDirection:'row', marginBottom:8, width:'40%'}}>
            <div>
              <p style={{margin:0, padding:0}}>{user.name}</p>
              <p style={{margin:0, padding:0, fontSize:14, fontWeight:600}}>{user.id}</p>
            </div>

          </div>


          <div style={{width:'100%'}}>

            <div
              onClick={() => handleClickUsersServices(user.id)}
                 style={{
                   cursor:'pointer',
                   padding:8,
                   // borderBottom:'1px solid #ccc',
                   display:'flex',
                   flexDirection:'row'
                 }}
            >
              <div style={{display:'flex', flexDirection:'row'}}>
                <div style={{marginLeft:-50}}>
                  {(idCheckedUserState && idCheckedUserState.length>0 && idCheckedUserState.includes(user.id))?<IndeterminateCheckBoxIcon/>:<AddBoxIcon />}
                </div>
                <div style={{width:150, color:'#888', marginLeft:24}}>
                  Всего сервисов:
                </div>
                <div style={{ fontWeight:'900', fontSize:13}}>
                  {allServicesRequestsQty}
                </div>
              </div>
              <div style={{display:'flex', flexDirection:'row', marginLeft:50}}>
                <div style={{width:200, color:'#888'}}>
                  запросов к сервисам:
                </div>
                <div style={{ fontWeight:'900', fontSize:15}}>
                  {allServicesRequests}
                </div>
              </div>

            </div>
            {(user.services && idCheckedUserState && idCheckedUserState.length>0 && idCheckedUserState.includes(user.id) )? user.services.map(service=>ServiceList(service)):null}

          </div>

        </div>
      </CSSTransition>

    )
  }
  const ServiceList = (service) => {

    return(
      <>
        <div style={{display:'flex', flexDirection:'row',
        transition: 'margin 1s',
        margin: 0,
        backgroundColor:(service.selectedRequests>1000)?'#ef5350':null , color:(service.selectedRequests>1000)?'white':null}}>
        <div style={{borderLeft:'2px solid #333333', borderBottom:'2px solid #FFFFFF', paddingBottom:4, width:'100%'}}>
          <p style={{margin:0, padding:0, paddingLeft:8}}>{service.name}</p>
          <p style={{margin:0, padding:0, fontSize:14, fontWeight:600, paddingLeft:8}}>{service.id}</p>
        </div>
        <div style={{cursor:'pointer', borderBottom:'2px solid #FFFFFF', paddingBottom:4, paddingRight:4, width:'30%', textAlign:'right', display:'flex', flexDirection:'row'}}
             onClick={()=>{
               getKeyHistory({socketConnection, fetchOptions, key_id: service.keyId,
                 from: activeIntervalTime.from, to: activeIntervalTime.to}
               )
               console.log('click getKeyHistory')
               dispatch(openModalAction({id:service.keyId}))
               // dispatch(loadingApp(LOADED))
             }}
        >
          {service.keyId ? <><div style={{textAlign:'right', width:'50%'}}>
              <p style={{margin:0, padding:0, paddingLeft:8, fontSize:10, fontWeight:600}}>запросы</p>
              <p style={{margin:0, padding:0, paddingLeft:8, fontSize:(service.selectedRequests>1000)?24:14, fontWeight:600}}>{service.selectedRequests}</p>
            </div>
            <div style={{textAlign:'right', width:'100%'}}>
              <p style={{margin:0, padding:0, paddingLeft:8, fontSize:10, fontWeight:600}}>ID ключа</p>
              <p style={{margin:0, padding:0, paddingLeft:8, fontSize:(service.selectedRequests>1000)?24:14, fontWeight:600}}>{service.keyId}</p>
            </div></> : null}


        </div>
      </div>
        <div style={{marginLeft:16}}>
          {service.services && service.services.length>0?service.services.map((item)=>ServiceList(item)):null}
        </div>

</>

    )
  }

  return(
    <>
      {findUsersKeysServicesTree.users.map((user)=>User(user))}
    </>
  )
}
