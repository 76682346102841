import React, { useEffect, useState } from 'react';
import { getErrorFromPath, keysByDate, keysByDateForChartErrors } from '../../_helpers';
import { setOneError } from '../../store/actions';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ErrorTable from './_components/ErrorTable'


const ApiCheckDashboard = () => {

  const _currentTime = new Date().getTime()
  const _defaultInterval = 604_800_000 // 604_800_000 = 7 дней
  const timeIntervalDefaults = {
    from: _currentTime - _defaultInterval,
    to: _currentTime
  }
  const [timeInterval, setTimeInterval] = useState(timeIntervalDefaults)
  const [dataForChart, setDataFotChart] = useState(null)
  const [arrayDateWithErrors, setArrayDataFotChart] = useState(null)
  const [activeDateShowErrors, setActiveDateShowErrors] = useState(null)

  const month = {
    '01': 'jan',
    '02': 'feb',
    '03': 'mar',
    '04': 'apr',
    '05': 'may',
    '06': 'jun',
    '07': 'jul',
    '08': 'aug',
    '09': 'sep',
    '10': 'oct',
    '11': 'nov',
    '12': 'dec',
   }
   const dateToDate = (date) => {  // Принимаем формат дд.мм.гггг
     let _date = date.split('.')  // Преобразование даты в понятный формат датапарсера
     _date[1] = month[_date[1]]
     return  Date.parse(_date)
   }
  function byField(field) {
    return (a, b) => dateToDate(a[field]) > dateToDate(b[field]) ? 1 : -1; // сортировка преобразованных дат
  }

  useEffect(async ()=>{
    let errorsByDate = await getCheckingHistory(timeInterval)
    console.log('errorsByDate await getCheckingHistory: ', errorsByDate);
    errorsByDate.sort(byField('name'))
    for (let i = 0; i < errorsByDate.length; i++){
      if (dateToDate(errorsByDate[i].name) < timeInterval.from || dateToDate(errorsByDate[i].name) > timeInterval.to){
        errorsByDate.splice(errorsByDate[i], 1)
      }
    }
    console.log('errorsByDate: ', errorsByDate);
    setDataFotChart(errorsByDate)
  },[])

  const getCheckingHistory = async (interval) => {
    let errorsData = await getErrorFromPath(interval)
    console.log('errorsData: ', errorsData)
    let {errorsByDate, dateWithErrors} = await keysByDateForChartErrors(errorsData.data.message, 'time')
    console.log('errorsByDate: ', errorsByDate);
    setArrayDataFotChart(dateWithErrors)
    return errorsByDate
  }

  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    }
    ]
  console.log('dataForChart: ', dataForChart);
  console.log('arrayDateWithErrors: ', arrayDateWithErrors && arrayDateWithErrors);
  console.log('activeDateShowErrors: ', activeDateShowErrors && activeDateShowErrors);
  return (
      <>
        <div>ApiCheckDashboard</div>
        <div>from: <span>{timeInterval.from}</span></div>
        <div>to: <span>{timeInterval.to}</span></div>

        {dataForChart ? <div style={{height: 300, marginTop:30}}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={dataForChart}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="errors" fill="#8884d8" onClick={(e)=>{
                console.log('errors e: ', e);
                setActiveDateShowErrors(e.name);
                }
              }/>
              <Bar dataKey="noerrors" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>: null}
        {activeDateShowErrors && arrayDateWithErrors ? <ErrorTable props={{activeDateShowErrors, arrayDateWithErrors}} /> : null}



      </>
    )

}

export default ApiCheckDashboard
