import React, { useRef, useState, useEffect, memo } from 'react';
import { Popper, Paper, MenuItem } from '@material-ui/core';


function PopoverBlock({ anchorRef, open, content, placement, onClick }) {
  const [anchorEl, setAnchorEl] = useState(true);
  const [currentBlock, setCurrentBlock] = useState(null);

  useEffect(() => {
    setCurrentBlock(anchorRef)
  }, [anchorRef])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = open ? 'simple-popover' : undefined;

  return (
    <div>

      <Popper
        id={id}
        open={open}
        anchorEl={currentBlock?.current}
        onClose={handleClose}
        placement={placement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Paper style={{ minWidth: "200px", maxWidth: "500px" }} elevation={16} >
          {content.map((item, key) => <MenuItem key={key} onClick={() => onClick(item)} > {item.name}</MenuItem>)}
        </Paper>
      </Popper>
    </div >
  );
}

export default PopoverBlock