import React, {useEffect, useState} from 'react';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';
import { connect } from 'react-redux';
import { auth, code, setAlert } from '../../store/actions';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ALERT_403, ALERT_TYPE_MODAL_ERROR, ALERT_TYPE_MODAL_INFO, DELETE_ALERT } from '../../constants';
import {
  CSSTransition,
  TransitionGroup, Transition,
} from 'react-transition-group';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = ({alert, authkey, rightskey, dispatch, authCheck}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [count, setCount] = useState(0)
  let Global = (typeof window !== 'undefined' ? window : global)
  let localStorage = Global.localStorage
  // console.log("localStorage: ", localStorage)
  //
  // const authkeyStorage = localStorage.getItem('authkey')
  // const keyStorage = localStorage.getItem('key')
  //
  // if (!authkeyStorage && !keyStorage)
  // {
  //   navigate('/app/dashboard', { replace: true });
  //
  // }
  // useEffect(()=>{
  //   console.log('localStorage.clear()')
  //   localStorage.clear()
  // })
  useEffect(()=>{
    if (alert){
      setTimeout(()=>{
        dispatch(setAlert(DELETE_ALERT))
      }, 3000)
    }
  })

  let handleOnSubmit = (data)=>{
    console.log("handleOnSubmit data: ", data)
    dispatch(auth(data))
    // navigate('/app/dashboard', { replace: true });
  }
  let handleOnSubmitCode = (data)=>{
    console.log("handleOnSubmitCode data: ", data)
    dispatch(code(data))
    navigate('/app/dashboard', { replace: true });
  }

  //   //TODO убрать из финалки
  //   // ==========|||||============
  // function miniAuth() {
  //   let c = count
  //   setCount(c+1)
  //   if (count>5){
  //     let Global = (typeof window !== 'undefined' ? window : global)
  //     let localStorage = Global.localStorage
  //     localStorage.setItem('authkey', '1')
  //     localStorage.setItem('key', '22')
  //     // navigate('/app/dashboard', { replace: true });
  //   }
  //
  // }
  //   // ==========|||||=============

  let alertError = () => {


    if(alert){
      let alertType
      switch (alert.code){
        case ALERT_403:
          alertType = ALERT_TYPE_MODAL_ERROR
          break
        default:
          alertType = ALERT_TYPE_MODAL_INFO
          break
      }
      return(
        <Transition
          //TODO хотелось плавное повление окна с сообщением
          in={true} mountOnEnter={true} timeout={2000}
        >
          <Alert severity={alertType} variant="filled">
            <AlertTitle>{alert.code}</AlertTitle>
            {alert.message}
          </Alert>
        </Transition  >
      )
    }

  }

  let loginEmailAuth = () => {

    return(
      <Formik
        initialValues={{
          email: 'example@example.com',
          password: '111111'
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Введите корректный email').max(255).required('Email обязателен'),
          password: Yup.string().max(255).required('Пароль обязательно')
        })}
        onSubmit={(values) => {
          handleOnSubmit(values)
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={3}>
              <Typography
                color="textPrimary"
                variant="h2"
                // onClick={()=>{miniAuth()}}
              >
                Авторизация
              </Typography>
            </Box>
            <Box
              mt={3}
              mb={1}
            >
              <Typography
                align="center"
                color="textSecondary"
                variant="body1"
              >
                Введите email и пароль
              </Typography>
            </Box>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Пароль"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            <Box my={2}>
              <Button
                color="primary"
                // disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Отправить данные
              </Button>
            </Box>


          </form>
        )}
      </Formik>
    )
  }

  let codeCheckRights = () => {

    return(
      <Formik
        initialValues={{
          code: '123456789'
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().max(255).required('Поле Код необходимо заполнить')
        })}
        onSubmit={(data) => {
          console.log("onSubmit data: ", data)
          handleOnSubmitCode(data)
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
          <form onSubmit={(e)=> {e.preventDefault();handleSubmit()}}>
            <Box mb={3}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Проверка персонального кода
              </Typography>
            </Box>
            <Box
              mt={3}
              mb={1}
            >
              <Typography
                align="center"
                color="textSecondary"
                variant="body1"
              >
                Введите Ваш персональный код
              </Typography>
            </Box>
            <TextField
              error={Boolean(touched.code && errors.code)}
              fullWidth
              helperText={touched.code && errors.code}
              label="Код"
              margin="normal"
              name="code"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.code}
              variant="outlined"
            />
            <Box my={2}>
              <Button
                color="primary"
                // disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Проверить код
              </Button>
            </Box>


          </form>
        )}
      </Formik>
    )
  }
// console.log("authkey: ", authkey)
// console.log("key: ", key)
  if (authCheck && authCheck.level == 'success'){
    console.log('LoginView authCheck success')
    window.location.pathname = "/app/dashboard"
    // return (
    //   <Navigate to="/app/dashboard" />
    // )
  }
  return (
    <Page
      className={classes.root}
      title="Авторизация"
    >

      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >

        <Container maxWidth="sm">
          {alertError()}
          {!authkey && !rightskey && loginEmailAuth()}
          {authkey && !rightskey && codeCheckRights()}
          {/*{codeCheckRights()}*/}
          <Typography
            color="textSecondary"
            variant="body1"
          >
            Нет аккаунта?
            {' '}
            <Link
              component={RouterLink}
              to="/register"
              variant="h6"
            >
              Зарегистрироваться
            </Link>
          </Typography>
        </Container>
      </Box>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    authkey: state.authkey,
    rightskey: state.rightskey,
    authCheck: state.authCheck,
    dispatch: state.dispatch
  };
};

export default connect(mapStateToProps)(LoginView);
