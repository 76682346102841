import {SERVER} from './server';

export const KEYS = {
  REG_AND_AUTH: '98fde409bdcf5abf',
  PASSWORD_RECOVERY: '1a6267616b4c9046',
  PASSWORD_CREATE: 'c744455c855a1fa0'
}

export const SERVICE = '122'
// export const PATH = 'https://cs.socmedica.com/api'
export const PATH = SERVER == 'dev' ? 'https://csdev.socmedica.com/api' : 'https://cs.socmedica.com/api'
export const PATH_APICHECK = SERVER == 'dev' ? 'https://service.socmedica.dev:9056' : 'https://service.socmedica.com:9056'
// export const DEV_PATH = 'https://csdev.socmedica.com/api'
// export const PROD_PATH = 'https://cs.socmedica.com/api'


export const PATH_RECOVERY = PATH +'/user/recovery'
// export const PROD_PATH_RECOVERY = PATH+'/user/recovery'

export const PATH_REGISTRATION = PATH+'/registration/send'
// export const PROD_PATH_REGISTRATION = PATH+'/registration/send'

export const PATH_AUTH = PATH+'/user/authorization'
// export const PROD_PATH_AUTH = PATH+'/user/authorization'

export const PATH_CHECKRIGHTS = PATH+'/umkb/checkRights'
// export const PROD_PATH_CHECKRIGHTS = PATH+'/umkb/checkRights'

export const PATH_LOGOUT = PATH+'/user/authexit'
// export const PROD_PATH_LOGOUT = PATH+'/user/authexit'

export const CHECKRIGHTS_RESPONSE_LEVEL_SUCCESS = 'success'
export const CHECKRIGHTS_RESPONSE_LEVEL_ERROR = 'error'

export const TIME_UPDATE = 30000
export const TIME_UPDATE_USERS_TREE = 10000
export const SET_CONNECT = 'SET_CONNECT'
export const LOADING = 'LOADING'
export const LOADED = 'LOADED'
export const SET_AUTHKEY = 'SET_AUTHKEY'
export const SET_KEY = 'SET_KEY'

export const SET_TREE = 'SET_TREE'
export const SET_SEARCH = 'SET_SEARCH'

export const UPDATE_KEYS = 'UPDATE_KEYS'

export const SET_AUTH = 'SET_AUTH'

export const SET_ALERT = 'SET_ALERT'
export const ALERT_403 = '403'
export const ALERT_TYPE_MODAL_ERROR = 'error'
export const ALERT_TYPE_MODAL_INFO = 'info'
export const DELETE_ALERT = 'DELETE_ALERT'

export const SET_MODAL_DATA = 'SET_MODAL_DATA'
export const DELETE_MODAL_DATA = 'DELETE_MODAL_DATA'
export const CLEAR_CLOSE_MODAL_SAVE_DATA = 'CLEAR_CLOSE_MODAL_SAVE_DATA'
export const CLEAR_CLOSE_MODAL = 'CLEAR_CLOSE_MODAL'
export const MODAL_OPEN_ACTION = 'open'
export const MODAL_CLOSE_ACTION = 'close'

export const TEXT_KEYS_QUANTITY = "Количество запросов"

export const AUTH_VALID_ALERT_TEXT = 'Данные проверены, теперь введите персональный код доступа к сервису'
export const CHECKRIGHTS_VALID_ALERT_TEXT = 'Код подтвержден, сейчас откроется мониторинг сервисов'

// *** Полные ключи в редюсер
export const SET_APP_STATUS = 'SET_APP_STATUS'
export const SET_COMPONENT_KEY_HISTORY_APP_STATUS = 'SET_COMPONENT_KEY_HISTORY_APP_STATUS'
export const SET_COMPONENT_USERS_TREE_ACTIVE_BY_DATE_APP_STATUS = 'SET_COMPONENT_USERS_TREE_ACTIVE_BY_DATE_APP_STATUS'
// *** Наименования компонентов для ключей редюсера
export const KEY_HISTORY = 'KEY_HISTORY'
export const USERS_TREE_ACTIVE_BY_DATE = 'USERS_TREE_ACTIVE_BY_DATE'

// *** Конец именований для редюсера

export const SET_ALL_USERS_SERVICES = 'SET_ALL_USERS_SERVICES'
export const SET_ALL_USERS = 'SET_ALL_USERS'
export const SET_ALL_USERS_PROPERTY = 'SET_ALL_USERS_PROPERTY'
export const SET_ALL_USERS_ID_ARRAY = 'SET_ALL_USERS_ID_ARRAY'
export const SET_ACTIVE_USERS_FLAG = 'SET_ACTIVE_USERS_FLAG'
export const SET_USERS_SUMMARY = 'SET_USERS_SUMMARY'
export const SET_USERS_IDS_STATE = 'SET_USERS_IDS_STATE'
export const SET_ALL_SERVICES = 'SET_ALL_SERVICES'
export const SET_ALL_KEYS = 'SET_ALL_KEYS'
export const SET_ALL_KEYS_ID_ARRAY = 'SET_ALL_KEYS_ID_ARRAY'
export const SET_SERVICES_BY_KEYS_ARRAY = 'SET_SERVICES_BY_KEYS_ARRAY'
export const SET_SERVICES_WITH_QTY_KEYS = 'SET_SERVICES_WITH_QTY_KEYS'
export const SET_SERVICES_SUMMARY = 'SET_SERVICES_SUMMARY'
export const SET_SERVICES_PIE_CHART = 'SET_SERVICES_PIE_CHART'
export const SET_ACTIVE_PIE_CHART_ELEMENT = 'SET_ACTIVE_PIE_CHART_ELEMENT'

export const SET_USERS_WITH_SERVICES_WITH_KEYS = 'SET_USERS_WITH_SERVICES_WITH_KEYS'
export const SET_SERVICES_WITH_USERS_WITH_KEYS = 'SET_SERVICES_WITH_USERS_WITH_KEYS'

export const SET_DATA_FOR_FIRST_PIE = 'SET_DATA_FOR_FIRST_PIE'
export const SET_FIRST_PIE_TOTAL_REQUEST_FLAG = 'SET_FIRST_PIE_TOTAL_REQUEST_FLAG'
export const SET_DATA_FOR_SECOND_PIE = 'SET_DATA_FOR_SECOND_PIE'

export const GET_KEYS_BY_ACTIVITY_FILL_USERS_SERVICES = 'GET_KEYS_BY_ACTIVITY_FILL_USERS_SERVICES'
export const SET_ACTIVE_INTERVAL_TIME = 'SET_ACTIVE_INTERVAL_TIME'

export const GET_DATA_FOR_KEY = 'GET_DATA_FOR_KEY'
export const CLEAR_DATA_FOR_KEY = 'CLEAR_DATA_FOR_KEY'

export const SET_ACTIVE_USERS_DATE_RANGE = 'SET_ACTIVE_USERS_DATE_RANGE'
export const SET_FIND_USERS_KEYS_SERVICES_TREE = 'SET_FIND_USERS_KEYS_SERVICES_TREE'
export const SET_FIND_USERS_KEYS_SERVICES_TREE_ERASE = 'SET_FIND_USERS_KEYS_SERVICES_TREE_ERASE'
export const SET_ID_CHECKED_USER = 'SET_ID_CHECKED_USER'
export const DELETE_ID_CHECKED_USER = 'DELETE_ID_CHECKED_USER'
export const SET_ONE_ERROR_BY_TAG = 'SET_ONE_ERROR_BY_TAG'
export const NAME = 'NAME'
export const SERVICE_ID = 'SERVICE_ID'
export const USER_ID = 'USER_ID'
export const DATE_RANGE = {
  m15: 'm15',
  H1: 'H1',
  D1: 'D1',
  W1: 'W1',
  M1: 'M1',
  M3: 'M3',
  M6: 'M6',
  M12: 'M12',
}

export const PAGINATION_START_ALLUSERS_NUMBER = 'PAGINATION_START_ALLUSERS_NUMBER'
export const PAGINATION_COUNT_ON_PAGE_ALLUSERS_NUMBER = 'PAGINATION_COUNT_ON_PAGE_ALLUSERS_NUMBER'
export const PAGINATION_START_ALLSERVICES_NUMBER = 'PAGINATION_START_ALLSERVICES_NUMBER'
export const PAGINATION_COUNT_ON_PAGE_ALLSERVICES_NUMBER = 'PAGINATION_COUNT_ON_PAGE_ALLSERVICES_NUMBER'
