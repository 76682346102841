/* eslint-disable import/prefer-default-export */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { act } from 'react-dom/test-utils';
import {
  Accordion, AccordionDetails, AccordionSummary, Paper
} from '@material-ui/core';
import { CSSTransition } from 'react-transition-group';
import { usersSummary } from '../../../../store/reducers/reducers';
import { LOADING } from '../../../../constants';
import { getKeyHistory } from '../../../../store/actions/socketActions';
import {
  actionAddIdCheckedUser,
  actionDeleteIdCheckedUser,
  actionIdCheckedUser,
  openModalAction
} from '../../../../store/actions';

export const OnlineUsers = () => {
  // appL: state.appLoadingStatus && state.appLoadingStatus.USERS_TREE_ACTIVE_BY_DATE && state.appLoadingStatus.USERS_TREE_ACTIVE_BY_DATE.loadingStatus,
  const appLoadingStatus = useSelector((state) => state.appLoadingStatus);
  const allKeys = useSelector((state) => state.allKeys);
  const usersSummary = useSelector((state) => state.usersSummary);
  const allUsersServices = useSelector((state) => state.allUsersServices);
  const allServices = useSelector((state) => state.allServices);
  const [services, setServices] = useState(null);
  const [allUMKBServices, setAllUMKBServices] = useState(null);
  const [users, setUsers] = useState(null);
  const [usersTree, setUsersTree] = useState(null); // <- данные для отображения
  const idCheckedUser = useSelector((state) => state.idCheckedUser);
  const [idCheckedUserState, setIdCheckedUser] = useState(idCheckedUser);

  const [activeUsers, setActiveUsers] = useState([]);
  const dispatch = useDispatch();
  const socketConnection = useSelector((state) => state.socketConnection);
  const allUsersProperty = useSelector((state) => state.allUsersProperty);
  const keysByActivityFillUsers = useSelector((state) => state.keysByActivityFillUsers);
  // console.log(keysByActivityFillUsers);
  const [data, setData] = useState(keysByActivityFillUsers && keysByActivityFillUsers.data ? keysByActivityFillUsers.data : null);
  useEffect(() => {
    if (keysByActivityFillUsers && keysByActivityFillUsers.data) {
      setData(keysByActivityFillUsers.data);
    }
  }, [keysByActivityFillUsers]);
  useEffect(() => {
    setIdCheckedUser(idCheckedUser);
  }, [idCheckedUser, idCheckedUser.length]);
  const forceUpdate = React.useCallback(() => setIdCheckedUser({}), []);

  const Global = typeof window !== 'undefined' ? window : global;
  const { localStorage } = Global;
  const authkeyStorage = localStorage.getItem('authkey');
  const keyStorage = localStorage.getItem('key');

  const fetchOptions = {
    authkey: authkeyStorage, key: keyStorage,
  };

  useEffect(() => {
    setAllUMKBServices(allServices);
  }, [allServices]);

  useEffect(() => {
    setServices(allKeys);
  }, [allKeys]);

  useEffect(() => {
    setUsers(usersSummary && usersSummary.data && usersSummary.data.online_ids);
  }, [usersSummary && usersSummary.data && usersSummary.data.online_ids]);

  useEffect(() => {
    if (services && activeUsers && allUMKBServices) {
      const _usersForTree = [];
      let _element;
      for (let u = 0; u < activeUsers.length; u++) {
        const _services = [];
        for (let i = 0; i < services.length; i++) {
          // console.log('services[i].user_id')
          if ((Number(services[i].user_id) == Number(activeUsers[u].id) && services[i].last_request && (services[i].last_request > (new Date().getTime() - 86400)))) {
            // eslint-disable-next-line camelcase
            const _serv_element = services[i];
            for (let a = 0; a < allUMKBServices.length; a++) {
              // console.log('_serv_element: ', _serv_element)
              if (allUMKBServices[a].id == _serv_element.service_id) {
                // console.log('services[i]: ', services[i])
                _serv_element.name = allUMKBServices[a].name;
              }
            }

            _services.push(_serv_element);

            // console.log('services[i].user_id == users[u].id: ', services[i].user_id)
          }
        }
        _element = { user_id: activeUsers[u], _services };
        // _element.user_id = users[u]
        _usersForTree.push(_element);
      }
      // console.log('_usersForTree: ', _usersForTree)
      window.usersTree = _usersForTree;
      setUsersTree(_usersForTree);
    }
  }, [services, users]);

  // Установка активного пользователя для сохранения открытого списка сервисов
  const handleClickUsersServices = (id) => {
    if (idCheckedUser.includes(id)) {
      dispatch(actionDeleteIdCheckedUser(id));
      forceUpdate();
    } else {
      dispatch(actionAddIdCheckedUser(id));
    }
  };
  const Item = (item) => {
    let allServicesRequests = 0;
    const allServicesRequestsQty = item.props.services.length;
    if (item.props.services) {
      for (let i = 0; i < item.props.services.length; i++) {
        allServicesRequests += item.props.services[i].selectedRequests;
      }
    }

    return (
      <CSSTransition
        in
        timeout={300}
        classNames="alert"
        unmountOnExit
        key={item.props.user.id}
      >
        <div style={{
          display: 'flex', flexDirection: 'row', backgroundColor: '#F4F6F8', marginBottom: 4, width: '100%'
        }}
        >
          <div style={{
            display: 'flex', flexDirection: 'row', marginBottom: 8, width: '40%'
          }}
          >
            <div>
              <p style={{ margin: 0, padding: 0 }}>{item.props.user.name}</p>
              <p style={{
                margin: 0, padding: 0, fontSize: 14, fontWeight: 600
              }}
              >
                {item.props.user.id}
              </p>
            </div>

          </div>
          <div style={{ width: '100%' }}>

            <div
              onClick={() => handleClickUsersServices(item.props.user.id)}
              style={{
                cursor: 'pointer',
                padding: 8,
                // borderBottom:'1px solid #ccc',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginLeft: -50 }}>
                  {(idCheckedUserState && idCheckedUserState.length > 0 && idCheckedUserState.includes(item.props.user.id)) ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />}
                </div>
                <div style={{ width: 150, color: '#888', marginLeft: 24 }}>
                  Всего сервисов:
                </div>
                <div style={{ fontWeight: '900', fontSize: 13 }}>
                  {allServicesRequestsQty}
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 50 }}>
                <div style={{ width: 200, color: '#888' }}>
                  запросов к сервисам:
                </div>
                <div style={{ fontWeight: '900', fontSize: 15 }}>
                  {allServicesRequests}
                </div>
              </div>

            </div>
            <div style={{ marginTop: 8 }}>
              {(item.props.services && idCheckedUserState && idCheckedUserState.length > 0 && idCheckedUserState.includes(item.props.user.id)) ? item.props.services.map((service) => ServiceList(service)) : null}
            </div>

          </div>

        </div>
      </CSSTransition>

    );
  };
  const ServiceList = (service) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          transition: 'margin 1s',
          margin: 0,
          backgroundColor: (service.selectedRequests > 1000) ? '#ef5350' : null,
          color: (service.selectedRequests > 1000) ? 'white' : null
        }}
        key={service.id}
      >
        <div style={{
          borderLeft: '2px solid #333333', borderBottom: '2px solid #FFFFFF', paddingBottom: 4, width: '100%'
        }}
        >
          <p style={{ margin: 0, padding: 0, paddingLeft: 8 }}>{service.name}</p>
          <p style={{
            margin: 0, padding: 0, fontSize: 14, fontWeight: 600, paddingLeft: 8
          }}
          >
            {service.serviceId}
          </p>
        </div>
        <div
          style={{
            cursor: 'pointer', borderBottom: '2px solid #FFFFFF', paddingBottom: 4, paddingRight: 4, width: '30%', textAlign: 'right', display: 'flex', flexDirection: 'row'
          }}
          onClick={() => {
            getKeyHistory({
              socketConnection,
              fetchOptions,
              key_id: service.keyId,
              from: allUsersProperty && allUsersProperty.from ? allUsersProperty.from
                : (Date.parse(new Date())) - 604800000,
              to: Date.parse(new Date())
            });
            console.log('click getKeyHistory');
            dispatch(openModalAction({ id: service.keyId }));
            // dispatch(loadingApp(LOADED))
          }}
        >
          <div style={{ textAlign: 'right', width: '50%' }}>
            <p style={{
              margin: 0, padding: 0, paddingLeft: 8, fontSize: 10, fontWeight: 600
            }}
            >
              запросы
            </p>
            <p style={{
              margin: 0, padding: 0, paddingLeft: 8, fontSize: (service.selectedRequests > 1000) ? 24 : 14, fontWeight: 600
            }}
            >
              {service.selectedRequests}
            </p>
          </div>
          <div style={{ textAlign: 'right', width: '100%' }}>
            <p style={{
              margin: 0, padding: 0, paddingLeft: 8, fontSize: 10, fontWeight: 600
            }}
            >
              ID ключа
            </p>
            <p style={{
              margin: 0, padding: 0, paddingLeft: 8, fontSize: (service.selectedRequests > 1000) ? 24 : 14, fontWeight: 600
            }}
            >
              {service.keyId}
            </p>
          </div>

        </div>
      </div>

    );
  };

  useEffect(() => {
    const _activeUsers = [];
    if (users) {
      for (let u = 0; u < users.length; u++) {
        for (let i = 0; i < allUsersServices.users.length; i++) {
          if (allUsersServices.users[i].id == users[u]) {
            // console.log('users[u]: ', allUsersServices.users[i])
            const _element = { ...allUsersServices.users[i] };

            _activeUsers.push(_element);
          }
        }
      }
      setActiveUsers(_activeUsers);
    }
  }, [allUsersServices]);
  // console.log(data, "data");
  if (!data) { return null; }
  // console.log(data, 'data');
  return (
    <>
      {data && data.length ? <div>{data.map((item, key) => <Item key={key} props={item} />)}</div> : null}
    </>

  );
};

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
