import {
  ALERT_403,
  AUTH_VALID_ALERT_TEXT,
  CHECKRIGHTS_RESPONSE_LEVEL_ERROR,
  CHECKRIGHTS_RESPONSE_LEVEL_SUCCESS,
  CHECKRIGHTS_VALID_ALERT_TEXT,
  CLEAR_CLOSE_MODAL,
  CLEAR_CLOSE_MODAL_SAVE_DATA,
  DELETE_ALERT,
  DELETE_MODAL_DATA,
  PATH_AUTH,
  PATH_CHECKRIGHTS,
  PATH_LOGOUT,
  PATH_RECOVERY,
  PATH_REGISTRATION,
  GET_DATA_FOR_KEY,
  KEYS,
  LOADED,
  LOADING,
  MODAL_CLOSE_ACTION,
  MODAL_OPEN_ACTION,
  PAGINATION_START_ALLUSERS_NUMBER,
  PROD_PATH_AUTH,
  PROD_PATH_CHECKRIGHTS,
  SET_ACTIVE_PIE_CHART_ELEMENT,
  SET_ACTIVE_USERS_FLAG,
  SET_ALERT,
  SET_ALL_KEYS,
  SET_ALL_KEYS_ID_ARRAY,
  SET_ALL_SERVICES,
  SET_ALL_USERS,
  SET_ALL_USERS_ID_ARRAY,
  SET_ALL_USERS_PROPERTY,
  SET_ALL_USERS_SERVICES,
  SET_APP_STATUS,
  SET_AUTH,
  SET_AUTHKEY,
  SET_CONNECT,
  SET_DATA_FOR_FIRST_PIE,
  SET_DATA_FOR_SECOND_PIE,
  SET_FIRST_PIE_TOTAL_REQUEST_FLAG,
  SET_KEY,
  SET_SEARCH,
  SET_TREE,
  SET_MODAL_DATA,
  SET_SERVICES_BY_KEYS_ARRAY,
  SET_SERVICES_SUMMARY,
  SET_SERVICES_WITH_QTY_KEYS,
  SET_SERVICES_WITH_USERS_WITH_KEYS,
  SET_USERS_SUMMARY,
  SET_USERS_WITH_SERVICES_WITH_KEYS,
  UPDATE_KEYS,
  SET_USERS_IDS_STATE,
  USERS_TREE_ACTIVE_BY_DATE,
  SET_ACTIVE_USERS_DATE_RANGE,
  GET_KEYS_BY_ACTIVITY_FILL_USERS_SERVICES,
  SET_ACTIVE_INTERVAL_TIME,
  SET_FIND_USERS_KEYS_SERVICES_TREE,
  SET_FIND_USERS_KEYS_SERVICES_TREE_ERASE, SET_ID_CHECKED_USER, DELETE_ID_CHECKED_USER, SET_ONE_ERROR_BY_TAG
} from '../../constants';
import axios from 'axios';
import { createArrayByField, GET, getIdsFromObjectsInArray, POST } from '../../_helpers';

// export class LoadingStatus {
//   // let componentName
//   constructor({componentName, loadingStatus}) {
//     this.componentName = componentName
//     this.loadingStatus = loadingStatus
//   }
// }
export const setComponentLoadingStatus = ({componentName, loadingStatus}) => {
  return {
    type: 'SET_COMPONENT_'+componentName+'_APP_STATUS',
    payload: {
      componentName,
      loadingStatus
    }
  };
};

export const loadingApp = (status) => {
  return {
    type: SET_APP_STATUS,
    payload: status
  };
};
export const setConnect = (data) => {
  return {
    type: SET_CONNECT,
    payload: data
  };
}
export const setAuthkey = (authkey) => {
  return {
    type: SET_AUTHKEY,
    payload: authkey
  };
};
export const setKey = (key) => {
  return {
    type: SET_KEY,
    payload: key
  };
};
export const setSearch = (data) => {
  return {
    type: SET_SEARCH,
    payload: data
  };
};
export const setTree = (data) => {
  return {
    type: SET_TREE,
    payload: data
  };
};
export const openModalAction = (modalData, action) => {
  switch (action) {
    case MODAL_OPEN_ACTION:
      return {
      type: SET_MODAL_DATA,
      payload: modalData
    }
      break
    case DELETE_MODAL_DATA:
      return {
        type: DELETE_MODAL_DATA,
        payload: null
      }
      break
    case CLEAR_CLOSE_MODAL_SAVE_DATA:
      return {
        type: CLEAR_CLOSE_MODAL,
        payload: null
      }
      break
    default:
      return {
        type: SET_MODAL_DATA,
        payload: modalData
      }
  }

};

export const usersServices = (data) => (dispatch) => {
  if (data) {
    dispatch(setAllUsersServices(data));
  } else {
    //TODO надо придумать обработчик и компонент для информирования о недоступности данных
  }
};

export const dataForFirstPieAction = (data) => {
  return {
    type: SET_DATA_FOR_FIRST_PIE,
    payload: data
  }
}
export const dataForSecondPieAction = (data) => {
  return {
    type: SET_DATA_FOR_SECOND_PIE,
    payload: data
  }
}

export const setAllUsersServices = (data) => {
  return {
    type: SET_ALL_USERS_SERVICES,
    payload: data
  };
};
export const setActiveUsersFlag = (data) => dispatch => {
  dispatch(loadingApp(LOADING));
  dispatch(_setActiveUsersFlag(data))
}
export const _setActiveUsersFlag = (data) => {
  return {
    type: SET_ACTIVE_USERS_FLAG,
    payload: data
  };
}

export const setFirstPieTotalRequestFlag = (data) => {
  return {
    type: SET_FIRST_PIE_TOTAL_REQUEST_FLAG,
    payload: data
  };
}
export const setAllUsers = (data) => dispatch => {
  // dispatch(_setAllUsers(data.data))
  dispatch(_setAllUsersProperty(data.filter))
  // dispatch(_setAllUsersIdArray(createArrayByField(data.data)))
  // dispatch(setComponentLoadingStatus({componentName: USERS_TREE_ACTIVE_BY_DATE, loadingStatus: LOADED}))
};
export const _setAllUsers = (data) => {
  return {
    type: SET_ALL_USERS,
    payload: data
  };
};
export const _setAllUsersProperty = (data) => {
  return {
    type: SET_ALL_USERS_PROPERTY,
    payload: data
  };
};
export const _setAllUsersIdArray = (data) => {
  return {
    type: SET_ALL_USERS_ID_ARRAY,
    payload: data
  };
};
export const setUsersSummary = (data) => {
  return {
    type: SET_USERS_SUMMARY,
    payload: data
  };
};
export const setUsersIdsState = (data) => {
  return {
    type: SET_USERS_SUMMARY,
    payload: data
  };
};
export const setAllServices = (data) => {
  return {
    type: SET_ALL_SERVICES,
    payload: data.data
  };
};
export const setServicesSummary = (data) => {
  return {
    type: SET_SERVICES_SUMMARY,
    payload: data
  };
};

export const actionFindUsersKeysServicesTree  = (data) => { //  *****  Построение таблицы текущей активности
  return {
    type: SET_FIND_USERS_KEYS_SERVICES_TREE,
    payload: data
  };
};
export const actionAddIdCheckedUser  = (data) => { //  *****  установка ID пользователя для списка сервисов
  return {
    type: SET_ID_CHECKED_USER,
    payload: data
  };
};
export const actionDeleteIdCheckedUser  = (data) => { //  *****  установка ID пользователя для списка сервисов
  return {
    type: DELETE_ID_CHECKED_USER,
    payload: data
  };
};
export const actionFindUsersKeysServicesTreeErase  = () => { //  *****  Построение таблицы текущей активности
  return {
    type: SET_FIND_USERS_KEYS_SERVICES_TREE_ERASE
  };
};
export const getUsersServicesByActivity = (data) => { //  *****  Построение таблицы текущей активности
  return {
    type: GET_KEYS_BY_ACTIVITY_FILL_USERS_SERVICES,
    payload: data
  };
};
export const setActiveIntervalTime = (data) => { //  *****  Установка требуемого интервала времени активности
  return {
    type: SET_ACTIVE_INTERVAL_TIME,
    payload: data
  };
};

export const setAllKeys = (data) => dispatch => {
  dispatch(_setAllKeys(data.data))
  dispatch(_setAllKeysIdArray(createArrayByField(data.data)))
  dispatch(_setServicesByKeysArray(createArrayByField(data.data, 'service_id', true)))
};
export const _setAllKeys = (data) => {
  return {
    type: SET_ALL_KEYS,
    payload: data
  };
}
export const _setAllKeysIdArray = (data) => {
  return {
    type: SET_ALL_KEYS_ID_ARRAY,
    payload: data
  };
}
export const _setServicesByKeysArray = (data) => {
  return {
    type: SET_SERVICES_BY_KEYS_ARRAY,
    payload: data
  };
}
export const updateKeys = (data) => {

  return {
    type: UPDATE_KEYS,
    payload: data.data
  };
}

export const setServicesWithQtyKeys = (services, keys) => {

  for (let s = 0; s < services.length; s++) {
    for (let i = 0; i < keys.length; i++) {
      if (services[s].id == keys[i].service_id) {
        if (!services[s]['count']) {
          services[s]['count'] = 1;
        } else {
          services[s]['count']++;
        }
      } else if (!services[s]['count']) {
        services[s]['count'] = 0;
      }
    }
  }
  return {
    type: SET_SERVICES_WITH_QTY_KEYS,
    payload: services
  };
};
export const setActivePieChartElement = (data) => {
  return {
    type: SET_ACTIVE_PIE_CHART_ELEMENT,
    payload: data
  };
};
export const setUsersWithServicesWithKeys = (data) => {
  return {
    type: SET_USERS_WITH_SERVICES_WITH_KEYS,
    payload: data
  };
};
export const setPageNumberUsersTree = (data) => {
  return {
    type: PAGINATION_START_ALLUSERS_NUMBER,
    payload: data
  };
};
export const setActiveUsersDateRange = (data) => {
  return {
    type: SET_ACTIVE_USERS_DATE_RANGE,
    payload: data
  };
};
export const setServicesWithUsersWithKeys = (data) => {
  return {
    type: SET_SERVICES_WITH_USERS_WITH_KEYS,
    payload: data
  };
};
export const setAlert = (data) => {
  if (data === DELETE_ALERT){
    return {
      type: DELETE_ALERT,
    }
  }
  return {
    type: SET_ALERT,
    payload: data
  };
}
export const getDataForKey = (data) => dispatch => {
  if (!data)data = "Empty"
  dispatch(_getDataForKey(data))
  // dispatch(loadingApp(LOADED))
}
export const clearDataForKey = () => dispatch => {
  dispatch(_getDataForKey(null))
}
export const _getDataForKey = (data) => {
  return {
    type: GET_DATA_FOR_KEY,
    payload: data
  };
}
//TODO доделать восстановление
export const recovery = (data) => dispatch => {
  // dispatch(loadingApp(LOADED));
  let sendData = {
    // AuthKey: '_Sg3rXG!HR@r2!4$',
    key: KEYS.PASSWORD_RECOVERY,
    service: "122",
    email: data.email,
  };
  let resultRecovery = axios({
    method: 'post',
    url: PATH_RECOVERY,
    data: sendData
  });
  return resultRecovery.then(
    response => {
      if (response.data.alert){
        dispatch(setAlert(response.data.alert))
      }
      if (response.data.authkey){
        dispatch(setAlert({message: AUTH_VALID_ALERT_TEXT}))
        let Global = (typeof window !== 'undefined' ? window : global)
        let localStorage = Global.localStorage
        localStorage.setItem('authkey', response.data.authkey)
        dispatch(setAuthkey(response.data.authkey))
      }
    }
  );
};
export const auth = (data) => dispatch => {
  // dispatch(loadingApp(LOADED));
  let sendData = {
    key: KEYS.REG_AND_AUTH,
    service: "122",
    email: data.email,
    password: data.password
  };
  let resultAuth = axios({
    method: 'post',
    url: PATH_AUTH,
    // url: PATH_AUTH,
    data: sendData
  });
  return resultAuth.then(
    response => {
      if (response.data.alert){
        dispatch(setAlert(response.data.alert))
      }
      if (response.data.authkey){
        dispatch(setAlert({message: AUTH_VALID_ALERT_TEXT}))
        let Global = (typeof window !== 'undefined' ? window : global)
        let localStorage = Global.localStorage
        localStorage.setItem('authkey', response.data.authkey)
        dispatch(setAuthkey(response.data.authkey))
      }
    }
  );
};
export const code = (data) => dispatch => {
  // let key = data.key
  if (data && data.code){

    let sendData = {
      key: data.code,
      application: "122",
    };
    console.log('!!!!!!!!!!!!!! ', PATH_CHECKRIGHTS,' sendData: ', sendData)
    let resultAuth = axios({
      method: 'post',
      url: PATH_CHECKRIGHTS,
      data: sendData
    });
    return resultAuth.then(
      response => {
        if (response.data.level && response.data.level == CHECKRIGHTS_RESPONSE_LEVEL_ERROR){
          dispatch(setAlert({code: ALERT_403, message: response.data.message}))
        }
        if (response.data.level && response.data.level == CHECKRIGHTS_RESPONSE_LEVEL_SUCCESS){
          dispatch(setAlert({message: CHECKRIGHTS_VALID_ALERT_TEXT}))
          let Global = (typeof window !== 'undefined' ? window : global)
          let localStorage = Global.localStorage
          // localStorage.setItem('authkey', response.data.authkey)
          // dispatch(setAuthkey(response.data.authkey))
          localStorage.setItem('key', sendData.key)
          dispatch(setKey(sendData.key))
          dispatch(setAuth({level: 'success'}))
        }
      }
    );
  }

};
export const registration = (data) => dispatch => {
  // dispatch(loadingApp(LOADED));
  let sendData = {
    // AuthKey: '_Sg3rXG!HR@r2!4$',
    key: KEYS.REG_AND_AUTH,
    service: "122",
    email: data.email,
    password: data.password,
    firstName: data.firstName,
    lastName: data.lastName,
  };
  let resultRegistration = axios({
    method: 'post',
    url: PATH_REGISTRATION,
    data: sendData
  });
  return resultRegistration.then(
    response => {
      if (response.data.alert){
        dispatch(setAlert(response.data.alert))
      }
    }
  );
};
export const logout = () => dispatch => {
  return GET({
    path: PATH_LOGOUT,
    data: {
      authkey: localStorage.getItem('authkey')
    },
    always: async (data) => {
      if (data.alerts){
        dispatch(setAlert(data.alerts[0].message))
      }
      localStorage.setItem("authkey", null)
      localStorage.setItem("key", null)
      await localStorage.clear();
    }
  });
}

export const serchABC = (text) => dispatch => {
  // console.log("text123");
  let data = {
    "key": "8fac6d5e35d439c2",
    "text": text,
    "lib": 902,
    "letterSearch": 1,
    "deep": 4,
    "linguistics": 1,
    "greedy": 2,
    "chain": 1,
    "isTryCorrectCulture": true 
  }

  return GET({
    path: "https://cs.socmedica.com/api/anj/searchconcept",
    data,
    always: async (data) => {
      // if (data.alerts){
      // console.log(data.concepts, "data.concepts");
      dispatch(setSearch(data.concepts))
      // }
      // localStorage.setItem("authkey", null)
      // localStorage.setItem("key", null)
      // await localStorage.clear();
    }
  });
}

export const postElement = (elements) => ({
  method: "POST",
  headers: { "Content-Type": "application/json" },
  body: JSON.stringify(elements),
});

export const checkTree = (libid) => async (dispatch) => {
  let data = {
    "key": "323029844b46c77f",
    "lib": [902],
    "level": [1051],
    "libid": [libid],
    "route": 0,
    "deep": 10
  }
  // console.log(data);

  const response = await fetch(`https://cs.socmedica.com/api/umkb/getsemanticnewbigid`, postElement(data));
  const answer = await response.json();
  // console.log(answer, "answer");
  dispatch(setTree(answer.names))
  // setAnswerFromServer(answer.alerts && answer.alerts[0].message || answer.alert.message)
  // setAnswerFromServer(answer)
  // await answer.level === "success" && navigate(`/confirm/${data.email}`)
}

export const checkAuth = (authkey) => dispatch => {
  return POST({
    path: PATH_CHECKRIGHTS,
    data: {
      authkey: authkey
    },
    success: data => {
      dispatch(setAuth(data))
    },
    fail: data => {
      console.log('checkAuth fail response: ', data);
    },
    always: async (data) => {
      if (data.alerts){
        // dispatch(setAlert(data.alerts[0].message))
      }
    }
  });
}
export const setAuth = (data) => {
  return {
    type: SET_AUTH,
    payload: data
  };
}

export const setOneError = (data) => {
  return {
    type: SET_ONE_ERROR_BY_TAG,
    payload: data
  };
}
