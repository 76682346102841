import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles, LinearProgress
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PeopleIcon from '@material-ui/icons/People';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { connect } from 'react-redux';
import { getUsersIdsState } from '../../../store/actions/socketActions';
import { TIME_UPDATE } from '../../../constants';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { Statistic } from 'semantic-ui-react'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.blue[600],
    height: 56,
    width: 56
  },
  onlineIcon: {
    color: colors.green[900],
    marginRight: 10
  },
  offlineIcon: {
    color: colors.red[900],
    marginRight: 10
  },
  offlineValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
    fontWeight: 900,
    fontSize: 20,
  },
  onlineValue: {
    color: colors.blue[900],
    marginRight: theme.spacing(1),
    fontWeight: 900,
    fontSize: 20
  },
  progressUsers: {
    backgroundColor: colors.red[600],
    color: colors.green[600],
    height: 16,
  },
  users_value_div:{

  },
}));
function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
let Global = typeof window !== 'undefined' ? window : global
let localStorage = Global.localStorage
let authkeyStorage = localStorage.getItem('authkey');
let keyStorage = localStorage.getItem('key');

let fetchOptions = {
  authkey: authkeyStorage, key: keyStorage,
}

const Users = ({ usersSummary, socketConnection, className, ...rest }) => {
  const classes = useStyles();
  let [timeOfUpdateUsersSummary, setTimeOfUpdateUsersSummary] = useState(null)
  // useEffect(()=>{
  //   if (socketConnection && socketConnection.connectionState === 1 && (timeOfUpdateUsersSummary+TIME_UPDATE)<=Date.parse(new Date())){
  //     getUsersIdsState({socketConnection, fetchOptions})
  //     // setTimeout(() => getUsersIdsState({socketConnection, fetchOptions}), TIME_UPDATE)
  //     setTimeOfUpdateUsersSummary(Date.parse(new Date()))
  //   }
  // }, )
  if (!usersSummary){
    return null
  }
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            {usersSummary.data?<Statistic label='Ключи'
                                          className='users_value_div'
                                          value={usersSummary.data.online_ids.length + usersSummary.data.offline_ids.length}
            />:null}
          </Grid>
          <Grid item>
            <Grid style={{display:'flex'}}>
              <ArrowUpwardIcon className={classes.onlineIcon} />
              <Typography
                className={classes.onlineValue}
                variant="body2"
              >
                {usersSummary.data && usersSummary.data.online_ids && usersSummary.data.online_ids.length}
              </Typography>
              <Typography
                color="textSecondary"
                variant="caption"
                className={classes.onlineValue}
              >
                Авторизовано
              </Typography>
            </Grid>
            <Grid style={{display:'flex'}}>
              <ArrowDownwardIcon className={classes.offlineIcon} />
              <Typography
                className={classes.offlineValue}
                variant="body2"
              >
                {usersSummary.data && usersSummary.data.offline_ids.length}
              </Typography>
              <Typography
                color="textSecondary"
                variant="caption"
                className={classes.offlineValue}
              >
                Неактивные
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
              <VpnKeyIcon className={classes.avatar} style={{backgroundColor:'white'}} />
          </Grid>
        </Grid>
      </CardContent>
      {usersSummary.data?<LinearProgress
        value={Number(usersSummary.data.online_ids.length)*100/(usersSummary.data.online_ids.length + usersSummary.data.offline_ids.length)}
        variant="determinate"
        className={classes.progressUsers}
      />:null}
    </Card>
  );
};


const mapStateToProps = (state) => {
  return {
    socketConnection: state.socketConnection,
    usersSummary: state.usersSummary,
    dispatch: state.dispatch
  };
};
export default connect(mapStateToProps)(Users);
