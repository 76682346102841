import React, { Component } from 'react';
import {
  actionFindUsersKeysServicesTree,
  getDataForKey, getUsersServicesByActivity,
  setActiveUsersFlag,
  setAllKeys,
  setAllServices,
  setAllUsers, setComponentLoadingStatus,
  setConnect, setServicesSummary, setUsersIdsState, setUsersSummary,
  updateKeys,
  usersServices
} from './actions';
import { connect } from 'react-redux';
import * as signalR from '@aspnet/signalr';
import { allKeys, servicesWithQtyKeys } from './reducers/reducers';
import {RESPONSE} from './actions/ServerControllerList';
import { getUsersIdsState } from './actions/socketActions';
import { KEY_HISTORY, LOADED, LOADING, USERS_TREE_ACTIVE_BY_DATE } from '../constants';
import { SERVER } from '../constants/server';

let Global = (typeof window !== 'undefined' ? window : global)
let localStorage = Global.localStorage

class LoadData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      targetId: '',
      connection: null,
      clientId: '',
      messages: [],
      key: localStorage.getItem('key'),
      authkey: localStorage.getItem('authkey'),
      badgeCount: 0
    };
  }

  componentDidMount() {
    // this.props.dispatch(usersServices());
    let dispatch = this.props.dispatch

    let connection = new signalR.HubConnectionBuilder()
      .withUrl(SERVER == 'dev' ? 'https://service.socmedica.dev:9017/wss/homehub?authkey=555&key=555' : 'https://service.socmedica.com:9017/wss/homehub?authkey=555&key=555')
      .build();

    connection.start().then(() => {
      this.setState({ connection });
    }).catch(function(err) {
      return console.error('connection.start error connection.start(): ', err.toString());
    });

    connection.on(RESPONSE.ReceiveUsersServices, function(data) {
      dispatch(usersServices(data));
    });

    connection.on(RESPONSE.UpdateUsersServices, function (data) {
      if (data && data.services){
      }
    });

    // connection.on(RESPONSE.GetKeysResponse, function (data) {
    //   dispatch(setAllKeys(data));
    // });
    connection.on(RESPONSE.GetKeyHistoryResponse, function (data) {
      // console.log('GetKeyHistoryResponse data: ', data);
      if (data && data.keyHistory){
        dispatch(getDataForKey(data.keyHistory))
      }
    });
    // connection.on(RESPONSE.GetKeyHistoryOldResponse, function (data) {
    //   dispatch(getDataForKey(data))
    // });
    connection.on(RESPONSE.GetUsersResponse, function (data) {
     dispatch(setAllUsers(data))
    });
    // connection.on(RESPONSE.GetActiveUsersResponse, function (data) {
    //  dispatch(setAllUsers(data))
    // });
    connection.on(RESPONSE.GetActiveUsersUsingKeyHistoryResponse, function (data) {
      dispatch(setAllUsers(data))
    });
    // connection.on(RESPONSE.GetServicesResponse, function (data) {
    //  dispatch(setAllServices(data))
    // });
    connection.on(RESPONSE.GetUsersIdsStateResponse, function (data) {
      dispatch(setUsersIdsState(data))
    });
    connection.on(RESPONSE.GetServicesIdsStateResponse, function (data) {
      dispatch(setServicesSummary(data))
    });
    connection.on(RESPONSE.GetUsersServicesByActivityResponse, function (data) {
      dispatch(getUsersServicesByActivity(data))
    });
    connection.on(RESPONSE.FindUsersKeysServicesTreeResponse, function (data) {
      dispatch(actionFindUsersKeysServicesTree(data))
    });
    connection.on(RESPONSE.FindManyUsersKeysServicesTreeResponse, function (data) {
      dispatch(actionFindUsersKeysServicesTree(data))
    });
    connection.on(RESPONSE.FindUsersKeysServicesWithKeyHistoryTreeResponse, function (data) {
      dispatch(actionFindUsersKeysServicesTree(data))
    });

    //TODO временно сделано для проверки, потом переделать в отдельный action
    // connection.on(RESPONSE.UpdateKeys, function (data) {
    //   dispatch(updateKeys(data))
    // });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.props.dispatch(setConnect(this.state.connection))
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    connect: state.connect,
    dispatch: state.dispatch
  };
};

export default connect(mapStateToProps)(LoadData);
