import { REQUEST } from './ServerControllerList';

/**
 * Отправка запроса на получение массива пользователей
 * #### --------------------------------------------
 * ### Параметры:
 * #### {object} fetchOptions - параметры авторизации
 * #### {number} page - активная страница
 * #### {number} limit - лимит на страницу = 10
 */
export function getUsers(param) {
  param.socketConnection
    .invoke(REQUEST.GetUsers, param.fetchOptions, ({ page: param.page, limit: param.limit }))
    .catch((err) => {
      return console.error('connection.invoke GetUsers error: ', err.toString());
    });
}
export function getUsersIdsState(param) {
  param.socketConnection
    .invoke(REQUEST.GetUsersIdsState, param.fetchOptions)
    .catch((err) => {
      return console.error('connection.invoke getUsersIdsState error: ', err.toString());
    });
}

export function getActiveUsers(param) {
  param.socketConnection
    .invoke(REQUEST.GetActiveUsers, param.fetchOptions, ({ page: param.page, limit: param.limit }))
    .catch((err) => {
      return console.error('connection.invoke GetActiveUsers error: ', err.toString());
    });
}
export function getActiveUsersUsingKeyHistory(param) {
  param.socketConnection
    .invoke(REQUEST.GetActiveUsersUsingKeyHistory, param.fetchOptions,
      {
        page: param.page, limit: param.limit, from: param.from, to: param.to
      })
    .catch((err) => {
      return console.error('connection.invoke GetActiveUsersUsingKeyHistory error: ', err.toString());
    });
}
export function findUsersKeysServicesTree(param) {
  // console.log(param, "param");
  param.socketConnection
    .invoke(REQUEST.FindUsersKeysServicesTree,
      {
        from: param.from, to: param.to, userId: param.userId, serviceId: param.serviceId, userName: param.userName
      })
    .catch((err) => {
      return console.error('connection.invoke GetActiveUsersUsingKeyHistory error: ', err.toString());
    });
}
export function findManyUsersKeysServicesTree(param) {
  param.socketConnection
    .invoke(REQUEST.FindManyUsersKeysServicesTree,
      {
        from: param.from, to: param.to, userIds: param.userIds, serviceId: param.serviceId, userName: param.userName
      })
    .catch((err) => {
      return console.error('connection.invoke FindManyUsersKeysServicesTree error: ', err.toString());
    });
}
export function findUsersKeysServicesWithKeyHistoryTree(param) {
  param.socketConnection
    .invoke(REQUEST.FindUsersKeysServicesWithKeyHistoryTree,
      {
        from: param.from, to: param.to, userIds: param.userIds, serviceId: param.serviceId, userName: param.userName
      })
    .catch((err) => {
      return console.error('connection.invoke FindUsersKeysServicesWithKeyHistoryTree error: ', err.toString());
    });
}

export function getKeys(param) {
  param.socketConnection
    .invoke(REQUEST.GetKeys, param.fetchOptions, ({ limit: param.limit ? param.limit : 0, user_ids: param.user_ids }))
    .catch((err) => {
      return console.error('connection.invoke GetKeys error: ', err.toString());
    });
}
export function getKeyHistory(param) {
  // console.log('getKeyHistory param: ', param)
  param.socketConnection
    .invoke(REQUEST.GetKeyHistory, ({
      ...param.fetchOptions, keyId: param.key_id, from: param.from, to: param.to || new Date().getTime()
    }))
    // .invoke(REQUEST.GetKeyHistoryOld, param.fetchOptions, ({ key_id: param.key_id, from: param.from, to: param.to }))
    .catch((err) => {
      return console.error('connection.invoke GetKeyHistory error: ', err.toString());
    });
}
export function getServices(param) {
  param.socketConnection
    .invoke(REQUEST.GetServices, param.fetchOptions, ({ page: 1, limit: 0 }))
    .catch((err) => {
      return console.error('connection.invoke GetServices error: ', err.toString());
    });
}
export function getServicesIdsState(param) {
  param.socketConnection
    .invoke(REQUEST.GetServicesIdsState, param.fetchOptions)
    .catch((err) => {
      return console.error('connection.invoke GetServicesIdsState error: ', err.toString());
    });
}
export function getKeysByActivityFillUsersServicesSocket(param) {
  // const Global = typeof window !== 'undefined' ? window : global;
  // const { localStorage } = Global;
  // console.log(param);
  // param.interval = { from: param.from, to: param.from - param.interval }
  param.interval = { from: param.from, to: param.to };
  // param.interval = {from:(new Date().getTime() - localStorage.getItem('interval') ), to:(new Date().getTime())}
  // console.log(param.interval);
  param.socketConnection
    // .invoke(REQUEST.GetUsersServicesByActivity, {...param.fetchOptions, from:(new Date().getTime() - 604_800_000 ), to:(new Date().getTime())})
    .invoke(REQUEST.GetUsersServicesByActivity, { ...param.fetchOptions, ...param.interval })
    // .invoke(REQUEST.GetKeysByActivityFillUsersServices, {from:(new Date().getTime() - 900000 ), to:(new Date().getTime())})
    .catch((err) => {
      return console.error('connection.invoke GetUsersServicesByActivity error: ', err.toString());
    });
}

export function getSearch(param) {
  // console.log(param, "paramparam");
  param.socketConnection
    .invoke(REQUEST.GetServicesIdsState, param.fetchOptions)
    .catch((err) => {
      return console.error('connection.invoke GetServicesIdsState error: ', err.toString());
    });
}

export const getElement = (elements) => ({
  method: "GET",
  headers: { "Content-Type": "application/json" },
  body: JSON.stringify(elements),
});

export const postElement = (elements) => ({
  method: "POST",
  headers: { "Content-Type": "application/json" },
  body: JSON.stringify(elements),
});

// export async function getSearchDb(text) {

//   // https://cs.socmedica.com/api/anj/searchconcept?key=8fac6d5e35d439c2&text=Соц&lib=902&letterSearch=1
//   // let data = {
//   //   "key": "8fac6d5e35d439c2",
//   //   "lib": [902],
//   //   "text": text,
//   //   "letterSearch": 1
//   // "key": "8fac6d5e35d439c2"
//   // "text": text
//   // "lib":902
//   // "letterSearch": 1
//   // "deep": 4
//   // "linguistics": 1
//   // "greedy": 2
//   // "chain": 3
//   // }
//   // let data = {
//   //   "key": "323029844b46c77f",
//   //   "lib": [902],
//   //   "level": [1051],
//   //   "libid": ["33580902"],
//   //   "route": 0,
//   //   "deep": 10
//   // }
//   // https://cs.socmedica.com/api/anj/searchconcept?key=8fac6d5e35d439c2&text=${text}&lib=902&letterSearch=1&deep=4&linguistics=1&greedy=3&chain=3
//   const response = await fetch(`https://cs.socmedica.com/api/anj/searchconcept?key=8fac6d5e35d439c2&text=${text}&lib=902&letterSearch=1&deep=4&linguistics=1&greedy=2&chain=3`, getElement());

//   const answer = await response.json();
  
//   console.log(response, answer?.concepts, "answer");
//   // dispatch(setSearch(answer.result));
//   return answer;
// }

// export async function getSearchSemantic(text) {

//   // https://cs.socmedica.com/api/anj/searchconcept?key=8fac6d5e35d439c2&text=Соц&lib=902&letterSearch=1
//   let data = {
//     "key": "323029844b46c77f",
//     "lib": [902],
//     "level": [1051],
//     "libid": ["33580902"],
//     "route": 0,
//     "deep": 10
//   }
//   // let data = {
//   //   "key": "323029844b46c77f",
//   //   "lib": [902],
//   //   "level": [1051],
//   //   "libid": ["33580902"],
//   //   "route": 0,
//   //   "deep": 10
//   // }
//   const response = await fetch("https://cs.socmedica.com/api/umkb/getsemantic", postElement(data));

//   // const answer = await response.json();
//   console.log(response, "answer");
//   // dispatch(setSearch(answer.result));

// }
// {
//   "key": "323029844b46c77f",
//   "lib": [902],
//   "level": [1051],
//   "libid": ["33580902"],
//   "route": 0,
//   "deep": 10
//  }
