/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Page from '../../../components/Page';
import Users from './Users';
import UsersTree from './UsersTree';
import Services from './Services';
import {
  checkAuth,
} from '../../../store/actions';
// import UsersServicesData from '../../../store/UsersServicesData';
// import PieCustom from '../PieChart/PieCustom';
import { REQUEST } from '../../../store/actions/ServerControllerList';
// import { OnlineUsers } from './OnlineUsers/OnlineUsers';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = ({
  dispatch,
  connect, authCheck
}) => {
  const classes = useStyles();
  // const [auth, setAuth] = useState(null)
  const Global = typeof window !== 'undefined' ? window : global;
  const { localStorage } = Global;
  const authkeyStorage = localStorage.getItem('authkey');
  const keyStorage = localStorage.getItem('key');

  const fetchOptions = {
    authkey: authkeyStorage, key: keyStorage,
  };
  // let fetchFilter = (filter) =>  filter

  useEffect(() => {
    dispatch(checkAuth(authkeyStorage));
  }, authCheck);
  useEffect(() => {
    if (connect && connect.connectionState === 1) {
      connect
        .invoke(REQUEST.GetServicesSummary, fetchOptions)
        .catch((err) => {
          return console.error('connection.invoke GetServicesSummary error: ', err.toString());
        });
      connect
        .invoke(REQUEST.JoinGroup, { ...fetchOptions, group: 'Keys' })
        .catch((err) => {
          return console.error(
            'connection.invoke JoinGroup error: ',
            err.toString()
          );
        });
    }
  }, connect);

  // useEffect(() => {
  //   // allUsersServices.keys && createUsersWithServicesWithKeysTree()
  //   //TODO Разобраться с правильным вложением ключей в массив user
  //   // allUsersServices.keys && createServicesWithUsersWithKeysTree()
  // });

  const [viewport, setViewport] = React.useState({
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 8
  });

  if (authCheck && authCheck.level != 'success') {
    return (
      <Navigate to="/login" />
    );
  }
  return (
    <Page className={classes.root} title="Мониторинг">
      {/* <UsersServicesData /> */}
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={4} sm={6} xl={4} xs={12}>
            <Users />
          </Grid>
          <Grid item lg={4} sm={6} xl={4} xs={12}>
            <Services />
          </Grid>
          {/* <Grid item lg={6} md={12} xl={6} xs={12}> */}
          {/*  <OnlineUsers /> */}
          {/* </Grid> */}
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <UsersTree />
          </Grid>

          {/* <Grid item lg={6} md={6} xl={6} xs={12}> */}
          {/*  <PieCustom type={'users'} /> */}
          {/* </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    authCheck: state.authCheck,
    connect: state.connect,
    // servicesPieChartData: state.servicesPieChartData,
    // allUsersServices: state.allUsersServices,
    // allKeys: state.allKeys,
    // allUsers: state.allUsers,
    // allServices: state.allServices,
    dispatch: state.dispatch
  };
};

export default connect(mapStateToProps)(Dashboard);
