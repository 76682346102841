import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
// import AccountView from './views/account/AccountView';
// import CustomerListView from './views/customer/CustomerListView';
import DashboardView from './views/reports/DashboardView';
import LoginView from './views/auth/LoginView';
import NotFoundView from './views/errors/NotFoundView';
// import ProductListView from './views/product/ProductListView';
import RegisterView from './views/auth/RegisterView';
// import SettingsView from './views/settings/SettingsView';
// import { checkAuth, setAlert, setAuthkey } from './store/actions';
// import { POST } from './_helpers';
// import { AUTH_VALID_ALERT_TEXT, PATH_AUTH, PATH_CHECKRIGHTS, KEYS } from './constants';
// import axios from 'axios';
import ApiCheck from './views/ApiCheck';

let Global = typeof window !== 'undefined' ? window : global
let localStorage = Global.localStorage
let authkeyStorage = localStorage.getItem('authkey');
let keyStorage = localStorage.getItem('key');

  const routes = [
    {
      path: 'app',
      element: <DashboardLayout />,
      children: [
        // { path: 'account', element: <AccountView /> },
        // { path: 'customers', element: <CustomerListView /> },
        { path: 'dashboard', element: (!authkeyStorage || !keyStorage) ?<Navigate to="/login" /> : <DashboardView /> },
        { path: 'apicheck', element: (!authkeyStorage || !keyStorage) ?<Navigate to="/login" /> : <ApiCheck /> },
        // { path: 'settings', element: <SettingsView /> },
        { path: 'login', element: <Navigate to="/login" /> },
        { path: '/', element: <Navigate to="/404" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: 'login', element: <LoginView /> },
        { path: 'register', element: <RegisterView /> },
        { path: '404', element: <NotFoundView /> },
        // { path: 'dashboard', element: <DashboardView /> },
        { path: '/', element: (!authkeyStorage || !keyStorage) ? <LoginView /> : <Navigate to="/app/dashboard" />},
        { path: '*', element: <Navigate to="/404" /> }
      ]
    }
  ];



export default routes;
