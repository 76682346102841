import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import './App.css'
import './mixins/chartjs';
import theme from './theme';
import routes from './routes';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
// import { HubConnection } from 'signalr-client-react';
// import PropTypes from 'prop-types';
// import UsersTree from './views/reports/DashboardView/UsersTree';
import LoadData from './store/LoadData';
// import * as signalR from "@aspnet/signalr";
// import * as signalR from "@microsoft/signalr";

const store = configureStore();

const App = () => {
  React.useEffect(()=>{

  })
  const routing = useRoutes(routes);

  return (
    <Provider store={store}>
    <ThemeProvider theme={theme}>
      <LoadData />
      <GlobalStyles />
      {routing}
    </ThemeProvider>
    </Provider>
  );
};


export default App;
