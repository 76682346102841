/* eslint-disable linebreak-style */
import axios from 'axios';
import { PATH_APICHECK, SERVICE } from '../constants';
// import { Platform, Linking } from 'react-native';

/**
 * Function returns true if input numeric and finite;
 *  @param n - input
 */
export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

// export function openURL(url) {
//     if (Platform.OS === 'web') {
//         window.open(url, '_blank');
//     } else {
//         Linking.canOpenURL(url)
//             .then((supported) => {
//                 if (supported) {
//                     return Linking.openURL(url);
//                 }
//             })
//             .catch((err) => console.error('An error occurred', err));
//     }
// }

export const serialize = (obj, prefix) => {
  const str = [];
  let p;

  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix || p;
      const v = obj[p]; // prefix + "[" + p + "]"
      str.push(v !== null && typeof v === 'object'
        ? serialize(v, k)
        : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
    }
  }

  if (str.length) {
    return `?${str.join('&').replace('?', '')}`;
  }

  return '';
};
const FETCH = ({
  data, success, fail, always, method, path, headers
}) => {
  const authkey = '';
  const data2 = {
    ...data,
    application: SERVICE
  };

  if (authkey) {
    // data2.authkey = authkey;
  }
  axios({
    method,
    // withCredentials: true,
    url: CREATE_API_URL({ path }),
    data: data2,
    headers: headers || { 'Content-Type': 'application/json;charset=UTF-8' },
  })
    .then((response) => {
      // if (response.status !== 200) {
      //     throw Error;
      // }

      let alerts = [];
      if (response.data.alert || response.data.alerts || response.data.Message) {
        if (response.data.alert) {
          alerts = [response.data.alert];
        }

        if (response.data.Message) {
          alerts = [response.data.Message];
        }

        if (response.data.alerts) {
          alerts = [...response.data.alerts];
        }

        alerts = alerts.length ? alerts : [{ message: response.data.toString() }];

        alerts = alerts.filter((alert) => alert.message || alert.title);
      }

      response.data.alerts = alerts;

      let isError = false;

      response.data.alerts.forEach((alert) => {
        if (alert.level === 'error') {
          isError = true;
        }
      });

      if (isError) {
        fail && fail(response.data.alerts);
        // return;
      } else {
        success && success(response.data);
      }

      always && always(response.data);
    })
    .catch((error) => {

      // console.log("axios error");
      // console.log(error);
      // if (error.response && error.response.hasOwnProperty('status')) {
      //     if (error.response.status === 401) {
      //         if (isChild) {
      //             redirectToLogin(window.parent);
      //         } else {
      //             redirectToLogin();
      //         }
      //     }
      // }

      // if (error.response.data.alert || error.response.data.alerts || error.response.data.Message) {

      //     if (error.response.data.alert) {
      //         error.response.data.alerts = [error.response.data.alert];
      //     }

      //     if (error.response.data.Message) {
      //         error.response.data.alerts = [{ message: error.response.data.Message }];
      //     }
      // }

      // let alerts = (error.response && error.response.data.alerts) || [{ message: error.toString() }];

      // fail && fail(alerts);
      // always && always(alerts);
    });
};

export const CREATE_API_URL = ({ path }) => {
  if (path.includes('://')) {
    return path;
  }

  return path;
};

export const GET = (params) => FETCH({ ...params, method: 'get', path: `${params.path}${serialize(params.data)}` });
export const DELETE = (params) => FETCH({ ...params, method: 'delete' });
export const POST = (params) => FETCH({ ...params, method: 'post' });
export const PUT = (params) => FETCH({ ...params, method: 'put' });

export const FILE = ({
  data, success, fail, always, path
}) => {
  const data2 = new FormData();

  Object.keys(data).forEach((key) => {
    data2.append(key, data[key]);
  });

  axios({
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    withCredentials: false,
    // withCredentials: !(process.env.NODE_ENV === 'production'),
    url: CREATE_API_URL({ path }),
    data: data2
  })
    .then((response) => {
      if (response.status !== 200 || response.data.alerts) {
        throw Error;
      }
      success && success(response.data);
      always && always();
    })
    .catch((error) => {
      const alerts = (error.response && error.response.data.alerts) || [{ message: error.toString() }];

      fail && fail(alerts);
      always && always();
    });
};

export const UUIDv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0; const
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

export const createArrayByField = (objectsArray, fieldName, unique = false) => {
  let result = [];
  objectsArray.forEach((item) => result.push(fieldName ? item[fieldName] : item.id));
  if (unique) {
    result = result.filter((v, i, a) => a.indexOf(v) === i);
  }
  return result;
};

export const keysByDate = (historykey, returnType, dateTag, interval) => {
  // console.log('historykey: ', historykey, interval);
  const dateKeys = [];
  const countDayKeys = [];

  const getKeyDate = (element) => {
    // console.log('element[dateTag]: ', element[dateTag]);
    const elD = new Date(Number(element[dateTag]));
    // console.log('elD: ', elD.getHours());
    let date = '';
    if (interval === 1) {
      date = `${elD.getDate()}.${elD.getMonth() + 1}.${elD.getFullYear()} ${elD.getHours() < 12 ? 0 : 12}:00 `;
    } else if (interval === 2) {
      date = `${elD.getDate()}.${elD.getMonth() + 1}.${elD.getFullYear()} ${elD.getHours() < 6 ? 0 : elD.getHours() < 12 ? 6 : elD.getHours() < 18 ? 12 : elD.getHours() < 24 ? 18 : 0}:00 `;
    } else if (interval === 3) {
      date = `${elD.getDate()}.${elD.getMonth() + 1}.${elD.getFullYear()} ${elD.getHours()}:00 `;
    } else if (interval === 4) {
      date = `${elD.getDate()}.${elD.getMonth() + 1}.${elD.getFullYear()} ${elD.getHours()}:${elD.getMinutes() < 15 ? "00" : elD.getMinutes() < 30 ? 15 : elD.getMinutes() < 45 ? 30 : elD.getMinutes() < 60 ? 45 : "00"} `;
    } else {
      date = `${elD.getDate()}.${elD.getMonth() + 1}.${elD.getFullYear()}`;
    }

    if (!dateKeys[date]) dateKeys[date] = [];
    if (!countDayKeys[date]) countDayKeys[date] = 0;
    dateKeys[date].push(element[dateTag]);
    // console.log('countDayKeys date: ', date);
    // console.log('countDayKeys[date]: ', countDayKeys[date])
    if (returnType === 'countDayKeys') {
      countDayKeys[date] += Number(element.count);
    } else {
      countDayKeys[date]++;
    }
  };
  for (let i = 0; i < historykey.length; i++) {
    getKeyDate(historykey[i]);
  }
  if (returnType === 'countDayKeys') {
    // console.log('countDayKeys: ', countDayKeys)
    return countDayKeys;
  }
  return countDayKeys;

  // console.log('dateKeys: ', dateKeys);
  // return dateKeys;
};

export const keysByDateForChartErrors = (historykey, dateTag) => {
  // console.log('historykey: ', historykey);
  const dateWithoutErrors = [];
  const dateWithErrors = [];
  const dateAll = [];
  const countDayKeys = [];
  const getKeyDate = (element) => {
    // console.log('element[dateTag]: ', element[dateTag]);
    const elD = new Date(Number(element[dateTag]));
    // console.log('elD: ', elD);
    const date = `${elD.getDate()}.${elD.getMonth() + 1}.${elD.getFullYear()}`;
    console.log('create date: ', date);
    console.log('create date elD: ', elD);
    if (!dateWithoutErrors[date]) dateWithoutErrors[date] = [];
    if (!dateWithErrors[date]) dateWithErrors[date] = [];
    if (!dateAll[date]) {
      dateAll[date] = {};
      dateAll[date].errors = 0;
      dateAll[date].noerrors = 0;
    }

    /*
    не было ошибок и надо забрать список из поля description
     */
    if (element.service_code === '200' && element.webserver_code === '200') {
      // dateWithoutErrors[date].push(element)
      if (!dateAll[date].name) dateAll[date].name = date;
      dateAll[date].noerrors++;

      if (element.description && element.description.noerrors && element.description.noerrors.length > 0) {
        for (let i = 0; i < element.description.noerrors.length; i++) {
          const elDate = new Date(Number(element.description.noerrors[i]));
          // console.log('elDate: ', elDate);
          const dateNoErrorsElement = `${elDate.getDate()}.${elDate.getMonth() + 1}.${elDate.getFullYear()}`;
          // console.log('dateNoErrorsElement: ', dateNoErrorsElement);
          if (!dateAll[dateNoErrorsElement]) {
            dateAll[dateNoErrorsElement] = {};
            dateAll[dateNoErrorsElement].name = dateNoErrorsElement;
            dateAll[dateNoErrorsElement].noerrors = 0;
            dateAll[dateNoErrorsElement].errors = 0;
          }
          dateAll[dateNoErrorsElement].noerrors++;
        }
      }
    } else {
      // dateWithErrors[date].push(element)
      if (!dateAll[date].name) dateAll[date].name = date;
      dateAll[date].errors++;
      dateWithErrors[date].push(element);
    }
  };
  for (let i = 0; i < historykey.length; i++) {
    getKeyDate(historykey[i]);
  }
  let _data = [];
  for (const key in dateAll) {
    _data.push(dateAll[key]);
  }

  console.log('dateWithErrors: ', dateWithErrors);
  console.log('dateAll: ', dateAll);
  _data = _data.sort((prev, next) => {
    if (new Date(prev.name).getTime() < new Date(next.name).getTime()) return -1;
    if (new Date(prev.name).getTime() < new Date(next.name).getTime()) return 1;
  });
  console.log('_data: ', _data);
  return { errorsByDate: _data, dateWithErrors };
  // return {dateWithoutErrors, dateWithErrors}
  // return countDayKeys
};

export const getErrorFromPath = async (interval) => {
  if (!interval) { // Вариант когда запрашиваем GET с тагом ошибки
    const query = window.location.search.substring(1);
    const params = new URLSearchParams(query);
    const tagError = params.get('tag');
    const key = window.localStorage.getItem('key');
    const apiCheckServiceUrl = PATH_APICHECK; // 'http://localhost:3005'
    const apiVersion = '/api/v1';
    const methodGetInfoByTagError = '/GetInfoByTagError';
    const dataForAxios = {
      key,
      tagError
    };
    if (tagError) { // Запрос в БД ApiCheck за инфо по временнОму идентификатору
      try {
        return axios.post(apiCheckServiceUrl + apiVersion + methodGetInfoByTagError, dataForAxios);
      } catch (e) {
        console.error('GetInfoByTagError error: ', e);
      }
    }
  } else { // Когда получаем список ошибок по интервалу времени
    if (interval.hasOwnProperty('from') && interval.hasOwnProperty('to')) {
      const apiCheckServiceUrl = PATH_APICHECK; // 'http://localhost:3005'
      // const apiCheckServiceUrl = 'http://localhost:3005'
      const apiVersion = '/api/v1';
      const methodGetInfoByInterval = '/GetInfoByInterval';
      const key = window.localStorage.getItem('key');
      const dataForAxios = {
        key,
        interval
      };
      try {
        return axios.post(apiCheckServiceUrl + apiVersion + methodGetInfoByInterval, dataForAxios);
      } catch (e) {
        console.error('methodGetInfoByInterval error: ', e);
      }
    }
  }
};
